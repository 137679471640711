import React, { useEffect, useRef } from "react";
import { SEEKER_PATH } from "../../constants";

const SeekerIFrame = ({jobData}) => {

    const iRef = useRef(null);
    const handleLoad = () => {
        setTimeout(() => {  
            if(jobData) {
                iRef.current.contentWindow.postMessage(JSON.stringify(jobData), '*');
            }
        }, [100]);
        setTimeout(() => {  
            if(jobData) {
                iRef.current.contentWindow.postMessage(JSON.stringify(jobData), '*');
            }
        }, [1000]);
     }

    return (
        <iframe 
            ref={iRef}
            src={`${SEEKER_PATH}emp-preview-job`} 
            onLoad={handleLoad}
            class="preview-popup-frame"
        ></iframe>
    )
}

export default SeekerIFrame;