import React, { Component } from 'react';
import InnerHeader from '../components/InnerHeader.js'
import { connect } from 'react-redux';
import {
  fetchAllApplicationsAPI,
  updateSlots,
  initializeJob,
  updateApplicantStatusAPI,
  sendOnlineIVInfoToSeeker,
  downloadApplicantList,
  getFilterOptions
} from '../helperFunctions/dataServices.js';
import DashboardTranslations from '../../DashboardTranslations.js';
import ConfirmModal from '../components/ConfirmModal'
import ConfirmCancelInterviewModal from '../components/ConfirmCancelInterviewModal'
import SeekerMessageModal from '../components/SeekerMessageModal'
import Tooltip from '@material-ui/core/Tooltip';
import close from '../../images/svg/close.svg'
import downloadIcon from '../../images/svg/download-icon.svg'
import profile from '../../images/svg/profile2.svg'
import profileAge from '../../images/svg/profile-age.svg'
import wjHired from '../../images/svg/wj-hired.svg'
import profile2 from '../../images/svg/profile2.svg';
import Modal from '@material-ui/core/Modal';
import ScaleLoader from 'react-spinners/ScaleLoader';
import './styles/applicant.scss';
import InfoModalNoApplications from "../components/InfoModalNoApplications";
import questionMark from "../../images/svg/question-mark.svg";
import InfoModalFirstApplication from "../components/InfoModalFirstApplication";
import {getErrorMessage} from "../../messages/errors";
import {getTooltipMessage} from "../../messages/tooltips";
import SuccessModal from '../components/SuccessModal.js';
import ScreeningModal from '../components/ScreeningModal.js';
import ErrorModal from '../components/ErrorModal.js';
import LoadingOverlay from 'react-loading-overlay';
import FilterBar from '../components/FilterBar.js';


var noApplicantsMessage= {
  "ALL": "予定されている面接はありません",
  "IF": "確定した面接はありません",
  "SL": "面接日を経過した面接はありません",
  "CBS-CBE": "キャンセルになった面接はありません",
}

class Applicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: [],
      loading: false,
      showCancelInterviewConfirmation: false,
      jobsOptions: [],
      hiredStatus: "",
      showOutcomeModal: false,
      showRequestNewSlotConfirmation: false,
      showNoSlotConfirmation: false,
      showMessageModal: false,
      nearestStation: "",
      selectedId: "",
      showSuccess: false,
      filterBy: "ALL",
      noApplicationModal: false,
      zeroApplications: false,
      firstApplicationModal: false,
      isOnlineIVList: {},
      showUrgentAction: false,
      applicationCount: {
        'ALL': 0,
        'URGENT': 0,
        'ESP': 0,
        'IF': 0,
        'SL': 0,
        'CBS-CBE': 0,
      },
      newScreeningStatus: '',
      showScreening: false,
      selectedJobId: '',
      filterState: {
        posts: [],
        locations: [],
        interviewers: [],
      },
      filterTypeSelected: null,
      checkedCount: 0,
      filterOptions: {
        job_titles: [],
        work_locations: [],
        members: [],
      }
    }
  }

  setCheckedCount(newCheckedCount) {
    this.setState({checkedCount: newCheckedCount})
  }

  setFilterState(newState) {
    this.setState({filterState: newState}, () => {
      const filteredApp = this.filteredApplications(this.state.applications)
      this.updateCounts(filteredApp)
    })
    setTimeout(() => {
      
    }, 200)
  }

  setFilterTypeSelected(typeSelected) {
    this.setState({filterTypeSelected: typeSelected})
  }

  cancelInterview(payload) {
    this.updateApplicantStatus(payload, this.state.selectedId)
    this.setState({showCancelInterviewConfirmation: false})
  }

  closeConfirmation() {
    this.setState({showCancelInterviewConfirmation: false})
  }

  handleErrorClose = () => {
    this.setState({showError: false, error: ''})
  }

  sendFeedback(e) {
    e.stopPropagation()
    e.preventDefault();
    var payload = {}
    if (this.state.hiredStatus) {
      payload.final_status_by_employer = this.state.hiredStatus;
    }
    payload.feedback_by_employer = this.state.seekerFeedback || '';
    let feedback_error = '';
    if(feedback_error !== '') {
      this.setState({feedback_error})
    } else {
      this.updateApplicantStatus(payload, this.state.selectedId)
      this.setState({showOutcomeModal: false})
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  updateCounts(newApplicationsList, update=false) {
    const applicationCount = this.state.applicationCount;
    applicationCount['ALL'] = 0;
    applicationCount['URGENT'] = 0;
    applicationCount['ESP'] = 0;
    applicationCount['IF'] = 0;
    applicationCount['SL'] = 0;
    applicationCount['CBS-CBE'] = 0;
    for(const application of newApplicationsList) {
      var showUrgentAction = false;
      showUrgentAction = showUrgentAction || application.status_v2 === 'AES';
      showUrgentAction = showUrgentAction || application.status_v2 === 'NSRS';
      showUrgentAction = showUrgentAction || application.status_v2 === 'AR';
      showUrgentAction = showUrgentAction || (
        application.status_v2 === 'IF' &&
        application.job.interview_location === null && 
        !this.hasOnlineIVDetails(application)
      );
      applicationCount['URGENT'] += +showUrgentAction;
      applicationCount['ALL'] += 1;
      applicationCount['ESP'] += +(application.status_v2 === 'NSAE' || application.status_v2 === 'NSRE' || application.status_v2 === 'ESP')
      applicationCount['SL'] += +(application.status_v2 === 'SL');
      applicationCount['CBS-CBE'] += +(
        application.status_v2 === 'CBS' ||
        application.status_v2 === 'ESRS' ||
        application.status_v2 === 'CBE' ||
        application.status_v2 === "RJ" ||
        application.status_v2 === "RS" ||
        application.status_v2 === "AB" ||
        application.status_v2 === "ESF" ||
        application.status_v2 === undefined
      );
      applicationCount['IF'] += +(application.status_v2 === 'IF');
    }
    if(this.props.updateUrgentUpdate && update) {
      this.props.updateUrgentUpdate()
    }
    let newFilterBy = ((this.state.filterBy === 'URGENT' && applicationCount['URGENT'] === 0) ? 'ALL' : this.state.filterBy);
    let toUpdate = {
      showUrgentAction: applicationCount['URGENT'] > 0,
      applicationCount: applicationCount,
    }
    if(update) {
      toUpdate = {
        ...toUpdate,
        applications: newApplicationsList,
        filterBy: newFilterBy
      }
    }
    this.setState(toUpdate)
    if(update) {
      var newSearch = "?filterBy=" + newFilterBy
      this.props.history.push({
        pathname: '/dashboard/applicants',
        search: newSearch,
      });
    } 
  }

  updateApplicantStatus(payload, id) {
    updateApplicantStatusAPI(payload, id).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({showAddJobs: false, loading: false}, () => {
          this.setState({showSuccess: true})
          var newApplicationsList = this.state.applications.map((item => {
            if (item.id === response.data.id) {
              var expandContractType = item.expandContractType
              var expandContractTypeMobile = item.expandContractTypeMobile
              item = response.data
              item.expandContractType = expandContractType
              item.expandContractTypeMobile = expandContractTypeMobile
            }
            return item;
          }))
          this.updateCounts(newApplicationsList, true)
        })
      }
      else {
        this.setState({loading: false, showError: true, error: response.response.data.error ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data.error}) : ""})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true})
    })
  }

  initializeJobs() {
    // this.setState({loading: true})
    initializeJob().then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        var jobsOptions = response.data
        this.setState({jobsOptions: jobsOptions})
      }
      else {
        // this.setState({loading: false})
      }
    })
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  componentDidMount() {
    this.fetchApplications()
    this.initializeJobs()
  }

  handleClose = () => {
    this.setState({ showSuccess: false, successMessage: '' });
    this.fetchApplications();
  };

  closeMessageModal() {
    this.setState({showMessageModal: false});
  }

  messageSeeker(selectedInputs) {
    sendOnlineIVInfoToSeeker(selectedInputs, this.state.selectedId).then((response) => {
      console.log({response, s: response.status})
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({showMessageModal: false, loading: false}, () => {
          this.setState({showSuccess: true, successMessage: "メッセージが送信されました。"})
        });
      } else {
        this.setState({
          loading: false,
          showError: true,
          error: response.response.data ? getErrorMessage('from_api_response', {'apiErrorMessage': response.response.data[0]}) : ""
        })
      }
    })
      .catch((e) => {
        this.setState({loading: false, showError: true})
      })
  }

  closeInfoModalNoApplications() {
    this.setState({noApplicationModal: !this.state.noApplicationModal})
  }

  closeInfoModalFirstApplication() {
    this.setState({firstApplicationModal: !this.state.firstApplicationModal})
  }

  requestUpdateSlot() {
    this.setState({loading: true})
    updateSlots(this.state.selectedId).then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState({showSuccess: true, successMessage: '面接日の変更依頼を送信しました。\n応募者が新しい面接日を選択するまでお待ちください。', successTitle: '変更依頼を送信完了'})
        this.setState({showAddJobs: false, loading: false, showRequestNewSlotConfirmation: false}, () => this.fetchApplications())
      }
      else {
        this.setState({loading: false, showRequestNewSlotConfirmation: false, showError: true, error: getErrorMessage('from_api_response', {'apiErrorMessage': response.data.error})})
      }
    })
    .catch((e) => {
      this.setState({loading: false, showError: true, showRequestNewSlotConfirmation: false})
    })
  }


  closeRequestNewSlotConfirmation() {
    this.setState({showRequestNewSlotConfirmation: false, showMessageModal: false})
  }

  closeNoSlotConfirmation() {
    this.setState({showNoSlotConfirmation: false, showMessageModal: false})
  }

  showScreening(application, status) {
    let stateChange = {selectedId: application.id, selectedJobId: application.job.id, showScreening: true}
    if(status === 'ESF') {
      stateChange.newScreeningStatus = status;
    } else if(status === 'ESP') {
      if(application.slot_count > 0) {
        stateChange.newScreeningStatus = status;
      } else {
        stateChange.newScreeningStatus = 'NO_SLOT';
      }
    }

    this.setState(stateChange);
  }

  closeScreening() {
    this.setState({selectedId: '', selectedJobId: '', newScreeningStatus: '', showScreening: false})
  }

  handleScreening() {
    const { selectedId, selectedJobId, newScreeningStatus } = this.state;
    if(newScreeningStatus === 'ESP' || newScreeningStatus === 'ESF') {
      this.setState({loading: true})
      updateApplicantStatusAPI({status: newScreeningStatus}, selectedId).then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({loading: false}, () => {
            this.closeScreening();
            var newApplicationsList = this.state.applications.map((item => {
              if(item.id === response.data.id){
                item = response.data
              }
              return item
            }))
            this.updateCounts(newApplicationsList, true)
          })
        }
      })
    } else {
      this.props.history.push('/dashboard/add-interview-times/'+selectedJobId)
    }
  }


  fetchApplications() {
    this.setState({loading: true})
    let component = this;
    getFilterOptions().then((filterResponse) => {
      if(filterResponse && filterResponse.status >= 200 && filterResponse.status < 300) {
        filterResponse.data.job_titles = filterResponse.data.job_titles.map(_ => _.replace(' (', '('))
        this.setState({filterOptions: filterResponse.data})
      }
      fetchAllApplicationsAPI().then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          let isOnlineIVList = {};
          let totalApplications = response.data.map((item, index) => {
            item.expandContractType = "expand"
            item.expandContractTypeMobile = "expand"

            isOnlineIVList[item.id] = item.job.interview_location == null;
            return item;
          })

          this.setState({isOnlineIVList: isOnlineIVList});

          if (totalApplications.length === 0) {
            const keyName = 'hasSeenNoApplicationModal' + component.props.user.email;
            const hasSeenNoApplicationModal = localStorage.getItem(keyName);

            if (hasSeenNoApplicationModal && hasSeenNoApplicationModal === 'true') {
              this.setState({
                zeroApplications: true,
                applications: totalApplications,
              });
            } else {
              this.setState({
                noApplicationModal: true,
                zeroApplications: true,
                applications: totalApplications,
              });
              localStorage.setItem(keyName, 'true');
            }
          } else {
            const applicationCount = this.state.applicationCount;
            applicationCount['ALL'] = 0;
            applicationCount['URGENT'] = 0;
            applicationCount['ESP'] = 0;
            applicationCount['IF'] = 0;
            applicationCount['SL'] = 0;
            applicationCount['CBS-CBE'] = 0;
            for(const application of totalApplications) {
              var showUrgentAction = false;
              showUrgentAction = showUrgentAction || application.status_v2 === 'AES';
              showUrgentAction = showUrgentAction || application.status_v2 === 'NSRS';
              showUrgentAction = showUrgentAction || application.status_v2 === 'AR';
              showUrgentAction = showUrgentAction || (
                application.status_v2 === 'IF' &&
                application.job.interview_location === null && 
                !this.hasOnlineIVDetails(application)
              );
              applicationCount['URGENT'] += +showUrgentAction;
              applicationCount['ALL'] += 1;
              applicationCount['ESP'] += +(application.status_v2 === 'NSAE' || application.status_v2 === 'NSRE' || application.status_v2 === 'ESP')
              applicationCount['SL'] += +(application.status_v2 === 'SL');
              applicationCount['CBS-CBE'] += +(
                application.status_v2 === 'ESRS' ||
                application.status_v2 === 'CBS' ||
                application.status_v2 === 'CBE' ||
                application.status_v2 === "RJ" ||
                application.status_v2 === "RS" ||
                application.status_v2 === "AB" ||
                application.status_v2 === "ESF" ||
                application.status_v2 === undefined
              );
              applicationCount['IF'] += +(application.status_v2 === 'IF');
            }
            if(this.props.updateUrgentUpdate) {
              this.props.updateUrgentUpdate()
            }
            const keyName = 'hasSeenFirstApplicationModalPost2' + component.props.user.email;
            const hasSeenFirstApplicationModal = localStorage.getItem(keyName);

            if (hasSeenFirstApplicationModal && hasSeenFirstApplicationModal === 'true') {
              this.setState({
                applications: totalApplications,
                showUrgentAction: applicationCount['URGENT'] > 0,
                applicationCount: applicationCount,
              });
            } else {
              this.setState({
                firstApplicationModal: true,
                applications: totalApplications,
                showUrgentAction: applicationCount['URGENT'] > 0,
                applicationCount: applicationCount,
              });
              localStorage.setItem(keyName, 'true');
            }
            var filterBy = applicationCount['URGENT'] > 0 ? 'URGENT' : 'ALL'
            var availableFilters = ['URGENT', 'ALL', 'IF', 'ESP', 'SL', 'CBS-CBE']
            if (this.props.history.location.search) {
              if (this.props.history.location.search.indexOf('?filterBy=') >= 0) {
                filterBy = this.props.history.location.search.replace('?filterBy=', '')
              }
              if (availableFilters.indexOf(filterBy) < 0) {
                filterBy = applicationCount['URGENT'] > 0 ? 'URGENT' : 'ALL'
              }
            }

            let newFilterBy = ((filterBy === 'URGENT' && applicationCount['URGENT'] === 0) ? 'ALL' : filterBy);

            var newSearch = "?filterBy=" + newFilterBy
            this.props.history.push({
              pathname: '/dashboard/applicants',
              search: newSearch,
            });

            this.setState({filterBy: newFilterBy})
          }

          setTimeout(function() { this.setState({loading: false}); }.bind(this), 2000);
        }
        else {
          this.props.history.push('/dashboard/jobs');
          this.setState({loading: false});
        }
      }).catch((e) => {
        this.setState({loading: false})
      })
    })
    
    .catch((e) => {
      this.setState({loading: false})
    })
  }

  backArrowOnClick() {
    this.props.history.goBack();
  }

  diffHours(dt2, dt1) {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.round(diff);
  }

  convert(timestamp, include_time=false) {
    var date = new Date(timestamp*1000);
    var cDate = date.getDate();
    var cMonth = date.getMonth();
    var cYear = date.getFullYear();
    if (timestamp) {
      const time_value = include_time ? (' ' + this.convertTime(timestamp)) : ''
      return [
        cYear,
        "/",
        cMonth+1,
        "/",
        cDate
      ].join('') + time_value;                                  // Glue the pieces together
    }
    else {
      return "";
    }
  }

  convertTime(timestamp) {
    var date = new Date(timestamp*1000);
    var cHours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var cMinutes = date.getMinutes() === 0 ? "00" : (date.getMinutes() < 10 ? ("0" + date.getMinutes()) : date.getMinutes());

    if (timestamp) {
      return [
        cHours,
        ":",
        cMinutes,
      ].join('');                                  // Glue the pieces together
    }
    else {
      return "";
    }
  }

  updateUrl(type) {
    this.setState({filterBy: type})
    var newSearch = "?filterBy=" + type
    this.props.history.push({
      pathname: '/dashboard/applicants',
      search: newSearch,
    });
  }

  showHide(id) {
    var element = document.getElementById(id)
    var expandContractType = "expand"
    var totalApplications = this.state.applications
    if (element.style.display === "flex") {
      element.style.display = "none"
      expandContractType = "expand"
    }
    else {
      element.style.display = "flex"
      expandContractType = "contract"
    }
    totalApplications = totalApplications.map((item, index) => {
      if (item.id === id) {
        item.expandContractType = expandContractType
      }
      return item;
    })

    this.setState({applications: totalApplications})
  }


  showHideMobile(id) {
    var newId = id+"-mobile"
    var element = document.getElementById(newId)
    var expandContractTypeMobile = "expand"
    var totalApplications = this.state.applications
    if (element.style.display === "block") {
      element.style.display = "none"
      expandContractTypeMobile = "expand"
    }
    else {
      element.style.display = "block"
      expandContractTypeMobile = "contract"
    }
    totalApplications = totalApplications.map((item, index) => {
      if (item.id === id) {
        item.expandContractTypeMobile = expandContractTypeMobile
      }
      return item;
    })

    this.setState({applications: totalApplications})
  }

  showMessageModal(application){
    this.setState({showMessageModal: true, nearestStation: application.job.nearest_station, selectedId: application.id})
  }

  hasOnlineIVDetails(application) {
    return !(!application.online_iv_detail && application.status_v2 === 'IF')
  }

  isUrgentApplication(application) {
    return (
      application.status_v2 === 'AES' ||
      application.status_v2 === 'NSRS' ||
      application.status_v2 === 'AR' || 
      (application.status_v2 === 'IF' && application.job.interview_location === null && !this.hasOnlineIVDetails(application))
    )
  }

  isCancelBtnEnabled(application) {
    return (
      application.status_v2 === 'IF' ||
      application.status_v2 === 'NSRS' ||
      application.status_v2 === 'NSRE' ||
      application.status_v2 === 'NSAE'
    );
  }

  getScreeningTooltip(application) {
    switch(application.status_v2 || application.status) {
      case 'AES':
        return ''
      case 'ESP':
        return '面接に招待しました'
      case 'ESF':
        return '選考不合格にしました'
      case 'ESRS':
        return '応募者が選考を辞退しました'
      default:
        return '選考しました'
    } 
  }

  isScreeningApplication(application) {
    return application.status_v2 === 'AES' || application.status_v2 === 'ESP' || application.status_v2 === 'ESF' || application.status_v2 === 'ESRS'
  }

  filteredApplications(applications) {
    if(this.state.filterTypeSelected && this.state.checkedCount > 0) {
      return applications.filter(item => {
        const dataList = this.state.filterState[this.state.filterTypeSelected].filter(f => f.checked).map(f => f.value)
        switch(this.state.filterTypeSelected) {
          case 'posts':
            return dataList.includes(item.job.display_name)
          case 'locations':
            return dataList.includes(item.job.work_location)
          case 'interviewers':
            return (
              item.job.interview_in_charge && item.job.interview_in_charge.display_name &&
              dataList.includes(item.job.interview_in_charge.display_name)
            )
          default:
            return false;
        }
      })
    }
    return applications
  }

  render() {
    var { loading, filterBy, noApplicationModal, firstApplicationModal, isOnlineIVList, applicationCount } = this.state;
    var allApplications = []
    var totalApplications = this.state.applications || [];

    if (filterBy === 'URGENT') {
      allApplications = totalApplications.filter(item => this.isUrgentApplication(item));
    }
    else if (filterBy === "ALL") {
      allApplications = totalApplications
    }
    else if (filterBy === "IF") {
      allApplications = totalApplications.filter(item => item.status_v2 === "IF")
    }
    else if (filterBy === "ESP") {
      allApplications = totalApplications.filter(item => item.status_v2 === "ESP" || item.status_v2 === "NSAE" || item.status_v2 === "NSRE")
    }
    else if (filterBy === "SL") {
      allApplications = totalApplications.filter(item => (item.status_v2 === "SL"))
    }
    else if (filterBy === "CBS-CBE") {
      allApplications = totalApplications.filter(item => (item.status_v2 === "CBS" || item.status_v2 === "CBE" || item.status_v2 === "RJ" || item.status_v2 === "RS" || item.status_v2 === "AB" || item.status_v2 === "ESF" || item.status_v2 === undefined))
    }
    allApplications = this.filteredApplications(allApplications)

    allApplications = allApplications.map((application, index) => {

      let newStatusMessage = '';
      let statusColorFont = '';
      let statusColorBg = '';

      switch(application.status_v2) {
        case 'IF':
          if(application.job.interview_location === null && !this.hasOnlineIVDetails(application)) {
            newStatusMessage = 'オンライン面接詳細の送信が必要です';
            statusColorFont = '#F00'; //red
            statusColorBg = 'rgba(255, 0, 0, 0.16)';
          } else {
            newStatusMessage = '面接日確定';
            statusColorFont = '#007AFF'; //blue
            statusColorBg = 'rgba(0, 122, 255, 0.16)';
          }
          break;
        case 'NSRS':
          newStatusMessage = '面接枠の登録が必要です';
          statusColorFont = '#F00'; //red
          statusColorBg = 'rgba(255, 0, 0, 0.16)';
          break;
        case 'AR':
          newStatusMessage = '面接結果の通知が必要です';
          statusColorFont = '#F00'; //red
          statusColorBg = 'rgba(255, 0, 0, 0.16)';
          break;
        case 'NSRE':
          newStatusMessage = '面接日の変更依頼中';
          statusColorFont = '#DEA705'; //yellow
          statusColorBg = 'rgba(255, 190, 0, 0.16)';
          break;
        case 'NSAE':
          newStatusMessage = '応募者に面接日の選択を依頼中';
          statusColorFont = '#DEA705'; //yellow
          statusColorBg = 'rgba(255, 190, 0, 0.16)';
          break;
        case 'SL':
          newStatusMessage = '面接合格の通知を送信しました';
          statusColorFont = '#17A91A'; //green
          statusColorBg = 'rgba(23, 169, 26, 0.16)';
          break;
        case 'CBE':
          newStatusMessage = '選考不合格';
          statusColorFont = '#555'; //grey
          statusColorBg = 'rgba(85, 85, 85, 0.16)';
          break;
        case 'CBS':
          newStatusMessage = '応募者が面接辞退';
          statusColorFont = '#555'; //grey
          statusColorBg = 'rgba(85, 85, 85, 0.16)';
          break;
        case 'AB':
          newStatusMessage = '面接を欠席';
          statusColorFont = '#555'; //grey
          statusColorBg = 'rgba(85, 85, 85, 0.16)';
          break;
        case 'RJ':
          newStatusMessage = '面接不合格';
          statusColorFont = '#555'; //grey
          statusColorBg = 'rgba(85, 85, 85, 0.16)';
          break;
        case 'AES':
          newStatusMessage = '面接に進むか選考をしてください';
          statusColorFont = '#F00'; //red
          statusColorBg = 'rgba(255, 0, 0, 0.16)';
          break;
        case 'ESF':
          newStatusMessage = '選考不合格にしました';
          statusColorFont = '#555'; //grey
          statusColorBg = 'rgba(85, 85, 85, 0.16)';
          break;
        case 'ESP':
          newStatusMessage = '応募者が面接日を選択するのを待っています';
          statusColorFont = '#DEA705'; //yellow
          statusColorBg = 'rgba(255, 190, 0, 0.16)';
          break;
        case 'ESRS':
            newStatusMessage = '応募者が選考を辞退しました';
            statusColorFont = '#555'; //grey
            statusColorBg = 'rgba(85, 85, 85, 0.16)';
            break;
        case 'RS':
          newStatusMessage = '内定辞退';
          statusColorFont = '#555'; //grey
          statusColorBg = 'rgba(85, 85, 85, 0.16)';
          break;
        default:
          break;  
      }

      const jobTypeUiOption = {
        PT1: {
          color: '#6CD4E2',
          text: 'アルバイト'
        },
        PT2: {
          color: '#6CD4E2',
          text: 'アルバイト'
        },
        FT: {
          color: '#5FBBFF',
          text: '正社員'
        },
        SSV: {
          color: 'rgba(166, 97, 255, 0.70)',
          text: '特定技能'
        },
      }

      let station_city = '';

      if (application.applicant_profile && application.applicant_profile.station_home && application.applicant_profile.station_city) {
        station_city = `${application.applicant_profile.station_city}`;
      }

      return (
        <div className='main-box-list'>
            <a 
              className='first-box'
              href={'/dashboard/applicant-details/'+application.id}
              //onClick={() => {this.props.history.push('/dashboard/applicant-details/'+application.id)}}
            >
              <div className='details'>
                <div className='profile-details'>
                  <img
                      className='appl-main-img'
                      alt="profile"
                      src={process.env.REACT_APP_ENV !== 'DEBUG' && application.applicant_profile && application.applicant_profile.profile_image ? application.applicant_profile.profile_image : profile2}
                  />
                  <div className='name-info-box'>
                    <div className='app-name'>
                      {application.applicant_profile.last_name} {application.applicant_profile.first_name}
                    </div>
                    <div className='dot-infos'>
                      <div className='info-box'>
                        <div className='dot'/>
                        <div className='info'>
                          {application.applicant_profile.age}才{application.applicant_profile.gender === 'M' ? '　男性' : '　女性'}
                        </div>
                      </div>
                      <div className='info-box'>
                        <div className='dot'/>
                        <div className='info'>
                          {application.applicant_profile.nationality}
                        </div>
                      </div>  
                      {application.applicant_profile.visa_type && <div className='info-box'>
                        <div className='dot'/>
                        <div className='info'>
                          {application.applicant_profile.visa_type}
                        </div>
                      </div>  }
                      <div className='info-box'>
                        <div className='dot'/>
                        <div className='info'>
                          {`${application.applicant_profile.station_home}駅 (${application.applicant_profile.station_prefecture}、${station_city})`}
                        </div>
                      </div>  
                    </div>
                  </div>
                </div>
                
                <div className='status-line'>
                  {application.job.job_type && <div className='job-type-box' style={{
                    backgroundColor: jobTypeUiOption[application.job.job_type].color
                  }}>
                    {jobTypeUiOption[application.job.job_type].text}
                  </div>}
                  <div className='job-status-box' style={{
                    color: statusColorFont,
                    backgroundColor: statusColorBg
                  }}>
                    {newStatusMessage}
                  </div>
                  {application.interview_start_timestamp && <div className='job-date'>
                  面接日: {this.convert(application.interview_start_timestamp, true)}
                  </div>}
                </div>
                <div className='icon-appl-mobile'>
                <div className='icon-mobile'>
              {application.selected_through_wj && <img
                  className='icon-img'
                  alt="profile"
                  src={wjHired}
                  />}
                <div className='icon-img-container'>
                  <img
                    className='icon-img'
                    alt="profile"
                    src={profileAge}
                  />
                  <div className='icon-text'>
                    <div className='tline'><div className='t3'>{new Date(application.created_at).getFullYear()}</div><div className='t2'>年</div></div>
                    <div className='t1'>登録</div>
                  </div>
                </div>
              </div>
                <div className='appl-btns-mobile'>
                  <div
                      className={`appl-action-icon ${application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application) ? 'appl-enabled' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if(application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application)) {
                          this.showMessageModal(application);
                        }
                      }}
                    >
                      <Tooltip title={application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application) ? "オンライン面接の詳細を通知する" : "今はこの操作を利用できません"} placement='bottom'>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M7.6875 19.875H17.4375V16.0312L22.3438 19.875V10.125L17.4375 13.9687V10.125H7.6875V19.875ZM4.375 25C3.875 25 3.4375 24.8125 3.0625 24.4375C2.6875 24.0625 2.5 23.625 2.5 23.125V6.875C2.5 6.375 2.6875 5.9375 3.0625 5.5625C3.4375 5.1875 3.875 5 4.375 5H25.625C26.125 5 26.5625 5.1875 26.9375 5.5625C27.3125 5.9375 27.5 6.375 27.5 6.875V23.125C27.5 23.625 27.3125 24.0625 26.9375 24.4375C26.5625 24.8125 26.125 25 25.625 25H4.375ZM4.375 23.125H25.625V6.875H4.375V23.125Z"
                            fill={application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application) ?  "#FF6565" : "#555555B2"}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                    <div
                      className={`appl-action-icon ${application.status_v2 === 'IF' ? 'appl-enabled' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if(application.status_v2 === 'IF') {
                          if(application.slot_count && application.slot_count > 0) {
                            const currDate = new Date();
                            const ivDate = new Date(application.interview_start_timestamp*1000);
                            const hours = Math.floor((ivDate - currDate) / 36e5);
                            let warningText = false;
                            if(hours >= 0 && hours < 24) {
                              warningText = true;
                            }
                            this.setState({showRequestNewSlotConfirmation: true, selectedId: application.id, cancelWarningText: warningText})
                          } else {
                            this.setState({showNoSlotConfirmation: true, selectedId: application.job.id})
                          }
                        }
                      }}
                    >
                      <Tooltip title={application.status_v2 === 'IF' ? "面接日の変更を依頼する" : "今はこの操作を利用できません"} placement='bottom'>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M6.33013 26.875C5.79288 26.875 5.33271 26.6835 4.94963 26.3004C4.56654 25.9173 4.375 25.4571 4.375 24.9199V7.27568C4.375 6.73843 4.56654 6.27826 4.94963 5.89518C5.33271 5.51212 5.79288 5.32059 6.33013 5.32059H8.44553V2.98083H10.1362V5.32059H19.9118V2.98083H21.5624V5.32059H23.6698C24.2071 5.32059 24.6672 5.51212 25.0503 5.89518C25.4334 6.27826 25.6249 6.73843 25.6249 7.27568V14.4391H24.0545V12.7324H5.94547V24.9199C5.94547 25.016 5.98554 25.1042 6.06569 25.1843C6.14581 25.2645 6.23396 25.3045 6.33013 25.3045H15.2083V26.875H6.33013ZM5.94547 11.162H24.0545V7.27568C24.0545 7.17953 24.0144 7.09139 23.9343 7.01124C23.8541 6.93112 23.766 6.89105 23.6698 6.89105H6.33013C6.23396 6.89105 6.14581 6.93112 6.06569 7.01124C5.98554 7.09139 5.94547 7.17953 5.94547 7.27568V11.162ZM17.6442 26.875V23.5721L24.4303 16.8173C24.5857 16.6619 24.754 16.5525 24.9351 16.4892C25.1161 16.4259 25.2972 16.3943 25.4783 16.3943C25.6722 16.3943 25.8617 16.4311 26.0468 16.5048C26.2319 16.5786 26.3982 16.6891 26.5456 16.8366L27.7018 18.0048C27.845 18.1603 27.9513 18.3285 28.0208 18.5096C28.0902 18.6907 28.1249 18.8718 28.1249 19.0529C28.1249 19.234 28.0878 19.4183 28.0135 19.6058C27.9393 19.7933 27.8317 19.9647 27.6909 20.1202L20.9471 26.875H17.6442ZM19.1346 25.3846H20.3221L24.3798 21.3077L23.8053 20.7139L23.2235 20.1274L19.1346 24.1971V25.3846ZM23.8053 20.7139L23.2235 20.1274L24.3798 21.3077L23.8053 20.7139Z"
                            fill={application.status_v2 === 'IF' ? "#1A9EFFB2" : "#555555B2"}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                    <div
                      className={`appl-action-icon ${this.isCancelBtnEnabled(application) ? 'appl-enabled' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if(this.isCancelBtnEnabled(application)) {
                          const currDate = new Date();
                          const ivDate = new Date(application.interview_start_timestamp*1000);
                          const hours = Math.floor((ivDate - currDate) / 36e5);
                          let warningText = false;
                          if(hours >= 0 && hours < 24) {
                            warningText = true;
                          }
                          this.setState({
                            showCancelInterviewConfirmation: true,
                            selectedId: application.id,
                            cancelWarningText: warningText,
                            successMessage: '選考不合格通知を送信しました、\n面接の予約はキャンセルされました。',
                            successTitle: '選考不合格通知を送信完了'
                          })
                        }
                      }}
                    >
                      <Tooltip title={this.isCancelBtnEnabled(application) ? "選考不合格を通知する" : "今はこの操作を利用できません"} placement='bottom'>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M12.0192 22.6931L10.9007 21.5745L13.8942 18.5938L10.9007 15.613L12.0192 14.4944L15 17.488L17.9808 14.4944L19.0993 15.613L16.1057 18.5938L19.0993 21.5745L17.9808 22.6931L15 19.6995L12.0192 22.6931ZM6.33012 26.875C5.79287 26.875 5.33271 26.6835 4.94963 26.3004C4.56654 25.9173 4.375 25.4571 4.375 24.9199V7.27568C4.375 6.73843 4.56654 6.27826 4.94963 5.89518C5.33271 5.51212 5.79287 5.32059 6.33012 5.32059H8.44553V2.98083H10.1362V5.32059H19.9118V2.98083H21.5624V5.32059H23.6698C24.2071 5.32059 24.6672 5.51212 25.0503 5.89518C25.4334 6.27826 25.6249 6.73843 25.6249 7.27568V24.9199C25.6249 25.4571 25.4334 25.9173 25.0503 26.3004C24.6672 26.6835 24.2071 26.875 23.6698 26.875H6.33012ZM6.33012 25.3045H23.6698C23.766 25.3045 23.8541 25.2645 23.9342 25.1843C24.0144 25.1042 24.0545 25.016 24.0545 24.9199V12.7324H5.94547V24.9199C5.94547 25.016 5.98554 25.1042 6.06569 25.1843C6.14581 25.2645 6.23396 25.3045 6.33012 25.3045ZM5.94547 11.162H24.0545V7.27568C24.0545 7.17953 24.0144 7.09139 23.9342 7.01124C23.8541 6.93112 23.766 6.89105 23.6698 6.89105H6.33012C6.23396 6.89105 6.14581 6.93112 6.06569 7.01124C5.98554 7.09139 5.94547 7.17953 5.94547 7.27568V11.162Z"
                            fill={this.isCancelBtnEnabled(application) ? "#1A9EFFB2" : "#555555B2"}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                    <div
                      className={`appl-action-icon ${application.status_v2 === 'AR' || application.status_v2 === 'SL' ? 'appl-enabled' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if(application.status_v2 === 'AR' || application.status_v2 === 'SL') {
                          const hiredStatus = application.status_v2 === 'SL' ? 'SL' : '';
                          this.setState({showOutcomeModal: true, selectedId: application.id, hiredStatus, seekerFeedback: '', feedback_error: '', feedback_text_error: ''})
                        }
                      }}
                    >
                      <Tooltip title={application.status_v2 === 'AR' || application.status_v2 === 'SL' ? "面接結果を通知する" : "今はこの操作を利用できません"} placement='bottom'>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M13.1778 19.6778L21.6874 11.1683L20.5689 10.0497L13.1778 17.4408L9.53522 13.8061L8.41666 14.9247L13.1778 19.6778ZM6.33012 25.625C5.79287 25.625 5.33271 25.4334 4.94963 25.0503C4.56654 24.6673 4.375 24.2071 4.375 23.6698V6.33016C4.375 5.79291 4.56654 5.33274 4.94963 4.94966C5.33271 4.56657 5.79287 4.37503 6.33012 4.37503H23.6698C24.2071 4.37503 24.6672 4.56657 25.0503 4.94966C25.4334 5.33274 25.6249 5.79291 25.6249 6.33016V23.6698C25.6249 24.2071 25.4334 24.6673 25.0503 25.0503C24.6672 25.4334 24.2071 25.625 23.6698 25.625H6.33012ZM6.33012 24.0545H23.6698C23.766 24.0545 23.8541 24.0144 23.9342 23.9343C24.0144 23.8542 24.0545 23.766 24.0545 23.6698V6.33016C24.0545 6.23399 24.0144 6.14584 23.9342 6.06572C23.8541 5.98557 23.766 5.9455 23.6698 5.9455H6.33012C6.23396 5.9455 6.14581 5.98557 6.06569 6.06572C5.98554 6.14584 5.94547 6.23399 5.94547 6.33016V23.6698C5.94547 23.766 5.98554 23.8542 6.06569 23.9343C6.14581 24.0144 6.23396 24.0545 6.33012 24.0545Z"
                            fill={application.status_v2 === 'AR' ? '#FF6565' : (application.status_v2 === 'SL' ? "#1A9EFFB2" : "#555555B2")}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                    <div
                      className={`appl-action-icon appl-enabled`}
                      onClick={() => {
                        this.props.history.push('/dashboard/add-interview-times/'+application.job.id)
                      }}
                    >
                      <Tooltip title={"面接日の登録"} placement='bottom'>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                        >
                          <path
                            d="M21.875 27.5V23.75H18.125V21.875H21.875V18.125H23.75V21.875H27.5V23.75H23.75V27.5H21.875ZM5.625 25C5.125 25 4.6875 24.8125 4.3125 24.4375C3.9375 24.0625 3.75 23.625 3.75 23.125V6.25C3.75 5.75 3.9375 5.3125 4.3125 4.9375C4.6875 4.5625 5.125 4.375 5.625 4.375H7.65625V2.5H9.6875V4.375H17.8125V2.5H19.8437V4.375H21.875C22.375 4.375 22.8125 4.5625 23.1875 4.9375C23.5625 5.3125 23.75 5.75 23.75 6.25V15.6875C23.4375 15.6458 23.125 15.625 22.8125 15.625C22.5 15.625 22.1875 15.6458 21.875 15.6875V12.1875H5.625V23.125H15.625C15.625 23.4375 15.6562 23.75 15.7187 24.0625C15.7812 24.375 15.8646 24.6875 15.9687 25H5.625ZM5.625 10.3125H21.875V6.25H5.625V10.3125Z"
                            fill={application.status_v2 === 'NSRS' ? "#FF6565" : "#1A9EFFB2"}
                          />
                        </svg>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                
                  <div className='detail-divider'/>
                <div className='main-line'>
                  <div className='line-title'>
                    応募記事
                  </div>
                  <div className='line-text'>
                    {application.job.display_name}
                  </div>
                </div>
                <div className='main-line'>
                  <div className='line-title'>
                    面接地
                  </div>
                  {application.job.interview_location === null ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 -1 17 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.29289 4.22844C1.48043 4.0409 1.73478 3.93555 2 3.93555H10C10.663 3.93555 11.2989 4.19894 11.7678 4.66778C12.2366 5.13662 12.5 5.77251 12.5 6.43555V12.4355C12.5 12.7008 12.3946 12.9551 12.2071 13.1427C12.0196 13.3302 11.7652 13.4355 11.5 13.4355H3.5C2.83696 13.4355 2.20107 13.1722 1.73223 12.7033C1.26339 12.2345 1 11.5986 1 10.9355V4.93555C1 4.67033 1.10536 4.41598 1.29289 4.22844ZM2 4.93555V10.9355C2 11.3334 2.15804 11.7149 2.43934 11.9962C2.72064 12.2775 3.10218 12.4355 3.5 12.4355H11.5V6.43555C11.5 6.03772 11.342 5.65619 11.0607 5.37489C10.7794 5.09358 10.3978 4.93555 10 4.93555L2 4.93555Z" fill="#33A8ED"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.751 5.25309C15.9052 5.34255 16.0001 5.50731 16.0001 5.68555V11.6855C16.0001 11.8638 15.9052 12.0285 15.751 12.118C15.5969 12.2075 15.4068 12.2081 15.252 12.1197L11.752 10.1197C11.5123 9.98266 11.429 9.67724 11.566 9.43748C11.703 9.19772 12.0084 9.11442 12.2482 9.25143L15.0001 10.824V6.54714L12.2482 8.11967C12.0084 8.25667 11.703 8.17338 11.566 7.93362C11.429 7.69386 11.5123 7.38843 11.752 7.25143L15.252 5.25143C15.4068 5.163 15.5969 5.16363 15.751 5.25309Z" fill="#33A8ED"/>
                      </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" style={{marginRight: 4}} viewBox="0 0 13 17" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.40983 5.20822C5.46304 5.20822 4.69552 5.97574 4.69552 6.92253C4.69552 7.86931 5.46304 8.63684 6.40983 8.63684C7.35662 8.63684 8.12414 7.86931 8.12414 6.92253C8.12414 5.97574 7.35662 5.20822 6.40983 5.20822ZM3.55265 6.92253C3.55265 5.34455 4.83185 4.06534 6.40983 4.06534C7.98781 4.06534 9.26702 5.34455 9.26702 6.92253C9.26702 8.50051 7.98781 9.77971 6.40983 9.77971C4.83185 9.77971 3.55265 8.50051 3.55265 6.92253Z" fill="#33A8ED"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.40983 1.77959C5.04584 1.77959 3.73772 2.32144 2.77323 3.28592C1.80874 4.25041 1.2669 5.55854 1.2669 6.92253C1.2669 9.28825 2.58797 11.4424 3.98386 13.0477C4.67482 13.8423 5.36724 14.4829 5.88705 14.9248C6.09262 15.0995 6.27054 15.2426 6.40983 15.3512C6.54912 15.2426 6.72704 15.0995 6.93261 14.9248C7.45242 14.4829 8.14485 13.8423 8.83581 13.0477C10.2317 11.4424 11.5528 9.28825 11.5528 6.92253C11.5528 5.55854 11.0109 4.25041 10.0464 3.28592C9.08195 2.32144 7.77382 1.77959 6.40983 1.77959ZM6.40983 16.0655C6.08213 16.5337 6.08197 16.5335 6.08179 16.5334L6.08132 16.5331L6.07998 16.5321L6.07576 16.5292L6.06123 16.5188C6.04888 16.51 6.03126 16.4973 6.00873 16.4809C5.96367 16.448 5.89892 16.4 5.81729 16.3376C5.65409 16.2129 5.42309 16.0303 5.14687 15.7956C4.59523 15.3267 3.85907 14.6459 3.12144 13.7976C1.66013 12.1171 0.124023 9.69974 0.124023 6.92253C0.124023 5.25543 0.786276 3.65661 1.96509 2.47779C3.14391 1.29897 4.74273 0.636719 6.40983 0.636719C8.07693 0.636719 9.67575 1.29897 10.8546 2.47779C12.0334 3.65661 12.6956 5.25543 12.6956 6.92253C12.6956 9.69974 11.1595 12.1171 9.69823 13.7976C8.9606 14.6459 8.22443 15.3267 7.67279 15.7956C7.39658 16.0303 7.16557 16.2129 7.00237 16.3376C6.92074 16.4 6.85599 16.448 6.81093 16.4809C6.7884 16.4973 6.77078 16.51 6.75844 16.5188L6.7439 16.5292L6.73968 16.5321L6.73834 16.5331L6.73787 16.5334C6.73769 16.5335 6.73753 16.5337 6.40983 16.0655ZM6.40983 16.0655L6.73787 16.5334C6.54111 16.6712 6.27855 16.6712 6.08179 16.5334L6.40983 16.0655Z" fill="#33A8ED"/>
                    </svg>}
                  <div className='line-text'>
                  {application.job.interview_location === null ? 'オンライン面接' : application.job.interview_location.address}
                  </div>
                </div>
                <div className='main-line main-line-reverse'>
                  <div className='main-line'>
                    <div className='line-title'>
                      応募日
                    </div>
                    <div className='line-text'>
                      {this.convert(application.applied_at_timestamp, false, true)}
                    </div>
                  </div>
                    {application.job.interview_in_charge && <div className='main-line'>
                    <div className='line-title'>
                      面接者
                    </div>
                    <div className='line-text'>
                      {application.job.interview_in_charge.display_name}
                    </div>
                  </div>}
                </div>
              </div>
              <div className='apply-box'>
              <div className='appl-btns'>
              <div
                  className={`appl-action-icon ${application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application) ? 'appl-enabled' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if(application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application)) {
                      this.showMessageModal(application);
                    }
                  }}
                >
                  <Tooltip title={application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application) ? "オンライン面接の詳細を通知する" : "今はこの操作を利用できません"} placement='bottom'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M7.6875 19.875H17.4375V16.0312L22.3438 19.875V10.125L17.4375 13.9687V10.125H7.6875V19.875ZM4.375 25C3.875 25 3.4375 24.8125 3.0625 24.4375C2.6875 24.0625 2.5 23.625 2.5 23.125V6.875C2.5 6.375 2.6875 5.9375 3.0625 5.5625C3.4375 5.1875 3.875 5 4.375 5H25.625C26.125 5 26.5625 5.1875 26.9375 5.5625C27.3125 5.9375 27.5 6.375 27.5 6.875V23.125C27.5 23.625 27.3125 24.0625 26.9375 24.4375C26.5625 24.8125 26.125 25 25.625 25H4.375ZM4.375 23.125H25.625V6.875H4.375V23.125Z"
                        fill={application.job && application.job.interview_location == null && !this.hasOnlineIVDetails(application) ?  "#FF6565" : "#555555B2"}
                      />
                    </svg>
                  </Tooltip>
                </div>
                <div
                  className={`appl-action-icon ${application.status_v2 === 'IF' ? 'appl-enabled' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if(application.status_v2 === 'IF') {
                      if(application.slot_count && application.slot_count > 0) {
                        const currDate = new Date();
                        const ivDate = new Date(application.interview_start_timestamp*1000);
                        const hours = Math.floor((ivDate - currDate) / 36e5);
                        let warningText = false;
                        if(hours >= 0 && hours < 24) {
                          warningText = true;
                        }
                        this.setState({showRequestNewSlotConfirmation: true, selectedId: application.id, cancelWarningText: warningText})
                      } else {
                        this.setState({showNoSlotConfirmation: true, selectedId: application.job.id})
                      }
                    }
                  }}
                >
                  <Tooltip title={application.status_v2 === 'IF' ? "面接日の変更を依頼する" : "今はこの操作を利用できません"} placement='bottom'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M6.33013 26.875C5.79288 26.875 5.33271 26.6835 4.94963 26.3004C4.56654 25.9173 4.375 25.4571 4.375 24.9199V7.27568C4.375 6.73843 4.56654 6.27826 4.94963 5.89518C5.33271 5.51212 5.79288 5.32059 6.33013 5.32059H8.44553V2.98083H10.1362V5.32059H19.9118V2.98083H21.5624V5.32059H23.6698C24.2071 5.32059 24.6672 5.51212 25.0503 5.89518C25.4334 6.27826 25.6249 6.73843 25.6249 7.27568V14.4391H24.0545V12.7324H5.94547V24.9199C5.94547 25.016 5.98554 25.1042 6.06569 25.1843C6.14581 25.2645 6.23396 25.3045 6.33013 25.3045H15.2083V26.875H6.33013ZM5.94547 11.162H24.0545V7.27568C24.0545 7.17953 24.0144 7.09139 23.9343 7.01124C23.8541 6.93112 23.766 6.89105 23.6698 6.89105H6.33013C6.23396 6.89105 6.14581 6.93112 6.06569 7.01124C5.98554 7.09139 5.94547 7.17953 5.94547 7.27568V11.162ZM17.6442 26.875V23.5721L24.4303 16.8173C24.5857 16.6619 24.754 16.5525 24.9351 16.4892C25.1161 16.4259 25.2972 16.3943 25.4783 16.3943C25.6722 16.3943 25.8617 16.4311 26.0468 16.5048C26.2319 16.5786 26.3982 16.6891 26.5456 16.8366L27.7018 18.0048C27.845 18.1603 27.9513 18.3285 28.0208 18.5096C28.0902 18.6907 28.1249 18.8718 28.1249 19.0529C28.1249 19.234 28.0878 19.4183 28.0135 19.6058C27.9393 19.7933 27.8317 19.9647 27.6909 20.1202L20.9471 26.875H17.6442ZM19.1346 25.3846H20.3221L24.3798 21.3077L23.8053 20.7139L23.2235 20.1274L19.1346 24.1971V25.3846ZM23.8053 20.7139L23.2235 20.1274L24.3798 21.3077L23.8053 20.7139Z"
                        fill={application.status_v2 === 'IF' ? "#1A9EFFB2" : "#555555B2"}
                      />
                    </svg>
                  </Tooltip>
                </div>
                <div
                  className={`appl-action-icon ${this.isCancelBtnEnabled(application) ? 'appl-enabled' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if(this.isCancelBtnEnabled(application)) {
                      const currDate = new Date();
                      const ivDate = new Date(application.interview_start_timestamp*1000);
                      const hours = Math.floor((ivDate - currDate) / 36e5);
                      let warningText = false;
                      if(hours >= 0 && hours < 24) {
                        warningText = true;
                      }
                      this.setState({
                        showCancelInterviewConfirmation: true,
                        selectedId: application.id,
                        cancelWarningText: warningText,
                        successMessage: '選考不合格通知を送信しました、\n面接の予約はキャンセルされました。',
                        successTitle: '選考不合格通知を送信完了'
                      })
                    }
                  }}
                >
                  <Tooltip title={this.isCancelBtnEnabled(application) ? "選考不合格を通知する" : "今はこの操作を利用できません"} placement='bottom'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M12.0192 22.6931L10.9007 21.5745L13.8942 18.5938L10.9007 15.613L12.0192 14.4944L15 17.488L17.9808 14.4944L19.0993 15.613L16.1057 18.5938L19.0993 21.5745L17.9808 22.6931L15 19.6995L12.0192 22.6931ZM6.33012 26.875C5.79287 26.875 5.33271 26.6835 4.94963 26.3004C4.56654 25.9173 4.375 25.4571 4.375 24.9199V7.27568C4.375 6.73843 4.56654 6.27826 4.94963 5.89518C5.33271 5.51212 5.79287 5.32059 6.33012 5.32059H8.44553V2.98083H10.1362V5.32059H19.9118V2.98083H21.5624V5.32059H23.6698C24.2071 5.32059 24.6672 5.51212 25.0503 5.89518C25.4334 6.27826 25.6249 6.73843 25.6249 7.27568V24.9199C25.6249 25.4571 25.4334 25.9173 25.0503 26.3004C24.6672 26.6835 24.2071 26.875 23.6698 26.875H6.33012ZM6.33012 25.3045H23.6698C23.766 25.3045 23.8541 25.2645 23.9342 25.1843C24.0144 25.1042 24.0545 25.016 24.0545 24.9199V12.7324H5.94547V24.9199C5.94547 25.016 5.98554 25.1042 6.06569 25.1843C6.14581 25.2645 6.23396 25.3045 6.33012 25.3045ZM5.94547 11.162H24.0545V7.27568C24.0545 7.17953 24.0144 7.09139 23.9342 7.01124C23.8541 6.93112 23.766 6.89105 23.6698 6.89105H6.33012C6.23396 6.89105 6.14581 6.93112 6.06569 7.01124C5.98554 7.09139 5.94547 7.17953 5.94547 7.27568V11.162Z"
                        fill={this.isCancelBtnEnabled(application) ? "#1A9EFFB2" : "#555555B2"}
                      />
                    </svg>
                  </Tooltip>
                </div>
                <div
                  className={`appl-action-icon ${application.status_v2 === 'AR' || application.status_v2 === 'SL' ? 'appl-enabled' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if(application.status_v2 === 'AR' || application.status_v2 === 'SL') {
                      const hiredStatus = application.status_v2 === 'SL' ? 'SL' : '';
                      this.setState({showOutcomeModal: true, selectedId: application.id, hiredStatus, seekerFeedback: '', feedback_error: '', feedback_text_error: ''})
                    }
                  }}
                >
                  <Tooltip title={application.status_v2 === 'AR' || application.status_v2 === 'SL' ? "面接結果を通知する" : "今はこの操作を利用できません"} placement='bottom'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M13.1778 19.6778L21.6874 11.1683L20.5689 10.0497L13.1778 17.4408L9.53522 13.8061L8.41666 14.9247L13.1778 19.6778ZM6.33012 25.625C5.79287 25.625 5.33271 25.4334 4.94963 25.0503C4.56654 24.6673 4.375 24.2071 4.375 23.6698V6.33016C4.375 5.79291 4.56654 5.33274 4.94963 4.94966C5.33271 4.56657 5.79287 4.37503 6.33012 4.37503H23.6698C24.2071 4.37503 24.6672 4.56657 25.0503 4.94966C25.4334 5.33274 25.6249 5.79291 25.6249 6.33016V23.6698C25.6249 24.2071 25.4334 24.6673 25.0503 25.0503C24.6672 25.4334 24.2071 25.625 23.6698 25.625H6.33012ZM6.33012 24.0545H23.6698C23.766 24.0545 23.8541 24.0144 23.9342 23.9343C24.0144 23.8542 24.0545 23.766 24.0545 23.6698V6.33016C24.0545 6.23399 24.0144 6.14584 23.9342 6.06572C23.8541 5.98557 23.766 5.9455 23.6698 5.9455H6.33012C6.23396 5.9455 6.14581 5.98557 6.06569 6.06572C5.98554 6.14584 5.94547 6.23399 5.94547 6.33016V23.6698C5.94547 23.766 5.98554 23.8542 6.06569 23.9343C6.14581 24.0144 6.23396 24.0545 6.33012 24.0545Z"
                        fill={application.status_v2 === 'AR' ? '#FF6565' : (application.status_v2 === 'SL' ? "#1A9EFFB2" : "#555555B2")}
                      />
                    </svg>
                  </Tooltip>
                </div>
                <div
                  className={`appl-action-icon appl-enabled`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.props.history.push('/dashboard/add-interview-times/'+application.job.id)
                  }}
                >
                  <Tooltip title={"面接日の登録"} placement='bottom'>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M21.875 27.5V23.75H18.125V21.875H21.875V18.125H23.75V21.875H27.5V23.75H23.75V27.5H21.875ZM5.625 25C5.125 25 4.6875 24.8125 4.3125 24.4375C3.9375 24.0625 3.75 23.625 3.75 23.125V6.25C3.75 5.75 3.9375 5.3125 4.3125 4.9375C4.6875 4.5625 5.125 4.375 5.625 4.375H7.65625V2.5H9.6875V4.375H17.8125V2.5H19.8437V4.375H21.875C22.375 4.375 22.8125 4.5625 23.1875 4.9375C23.5625 5.3125 23.75 5.75 23.75 6.25V15.6875C23.4375 15.6458 23.125 15.625 22.8125 15.625C22.5 15.625 22.1875 15.6458 21.875 15.6875V12.1875H5.625V23.125H15.625C15.625 23.4375 15.6562 23.75 15.7187 24.0625C15.7812 24.375 15.8646 24.6875 15.9687 25H5.625ZM5.625 10.3125H21.875V6.25H5.625V10.3125Z"
                        fill={application.status_v2 === 'NSRS' ? "#FF6565" : "#1A9EFFB2"}
                      />
                    </svg>
                  </Tooltip>
                </div>
              </div>
              <div className='icon-box'>
              {application.selected_through_wj && <img
                  className='icon-img'
                  alt="profile"
                  src={wjHired}
                  />}
                <div className='icon-img-container'>
                  <img
                    className='icon-img'
                    alt="profile"
                    src={profileAge}
                  />
                  <div className='icon-text'>
                    <div className='tline'><div className='t3'>{new Date(application.created_at).getFullYear()}</div><div className='t2'>年</div></div>
                    <div className='t1'>登録</div>
                  </div>
                </div>
              </div>
              <div className='approve-box'>
              <div
                  className={'open-btn'}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.props.history.push('/dashboard/applicant-details/'+application.id)
                  }}
                >
                  <div className='option-text'>詳細を見る</div>
                </div>
                <div
                  className={application.status_v2 === 'AES' ? 'accept-btn' : 'disabled-btn'}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (application.status_v2 === 'AES') this.showScreening(application, 'ESP')
                    }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <g clip-path="url(#clip0_175_2913)">
                      <path 
                        d="M1.22543 11.3164C0.84543 10.9364 0.84543 10.2964 1.22543 9.89641C1.62543 9.51641 2.26543 9.51641 2.64543 9.89641L6.80543 14.0564L18.2454 2.61641C18.6254 2.21641 19.2654 2.21641 19.6654 2.61641C20.0454 2.99641 20.0454 3.63641 19.6654 4.03641L7.50543 16.1764C7.12543 16.5764 6.48543 16.5764 6.08543 16.1764L1.22543 11.3164Z" 
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_175_2913">
                        <rect 
                          width="20" 
                          height="20" 
                          fill="white" 
                          transform="translate(0.94043 0.316406)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <div className='option-text'>面接する</div>
                </div>
                <div
                  className={(application.status_v2 === 'AES' || application.status_v2 === 'ESP') ? 'reject-btn' : 'disabled-btn'}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (application.status_v2 === 'AES' || application.status_v2 === 'ESP') this.showScreening(application, 'ESF')
                    }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path 
                      d="M1.32246 18.0898C0.813086 18.5992 0.813086 19.425 1.32246 19.9344C1.83184 20.4438 2.65772 20.4438 3.16709 19.9344L1.32246 18.0898ZM11.8627 11.2387C12.3721 10.7293 12.3721 9.90355 11.8627 9.39416C11.3533 8.88477 10.5276 8.88477 10.0182 9.39416L11.8627 11.2387ZM10.0182 9.39416C9.50879 9.90355 9.50879 10.7293 10.0182 11.2387C10.5276 11.7481 11.3533 11.7481 11.8627 11.2387L10.0182 9.39416ZM20.5584 2.54307C21.0678 2.03369 21.0678 1.20781 20.5584 0.698438C20.049 0.189062 19.2233 0.189062 18.7139 0.698438L20.5584 2.54307ZM11.8627 9.39416C11.3533 8.88477 10.5276 8.88477 10.0182 9.39416C9.50879 9.90355 9.50879 10.7293 10.0182 11.2387L11.8627 9.39416ZM18.7139 19.9344C19.2233 20.4438 20.049 20.4438 20.5584 19.9344C21.0678 19.425 21.0678 18.5992 20.5584 18.0898L18.7139 19.9344ZM10.0182 11.2387C10.5276 11.7481 11.3533 11.7481 11.8627 11.2387C12.3721 10.7293 12.3721 9.90355 11.8627 9.39416L10.0182 11.2387ZM3.16709 0.698438C2.65772 0.189062 1.83184 0.189062 1.32246 0.698438C0.813086 1.20781 0.813086 2.03369 1.32246 2.54307L3.16709 0.698438ZM3.16709 19.9344L11.8627 11.2387L10.0182 9.39416L1.32246 18.0898L3.16709 19.9344ZM11.8627 11.2387L20.5584 2.54307L18.7139 0.698438L10.0182 9.39416L11.8627 11.2387ZM10.0182 11.2387L18.7139 19.9344L20.5584 18.0898L11.8627 9.39416L10.0182 11.2387ZM11.8627 9.39416L3.16709 0.698438L1.32246 2.54307L10.0182 11.2387L11.8627 9.39416Z" 
                      fill={(application.status_v2 === 'AES' || application.status_v2 === 'ESP')? "#33A8ED" : 'white'}
                    />
                  </svg>
                  <div className='option-text'>面接しない</div>
                </div>
              </div>
              </div>
            </a>
          </div>
      )
    })

    return (
      <LoadingOverlay
            active={loading}
            styles={
              {
                wrapper: {
                  position: loading ? 'fixed' : 'absolute',
                  height: '100vh',
                  width: '100vw',
                  top: 0,
                  left: 0,
                  overflowX: 'hidden'
                }
              }
            }
            spinner
        >
        <div className="application-container">
          <InnerHeader
              primary={true}
              history={this.props.history}
              headerText="応募者の管理"
              backArrowOnClick={this.backArrowOnClick.bind(this)}
          />

          <ScaleLoader sizeUnit={"px"} height={50}
                       width={4} color={'#007AFF'} loading={loading}
          />

          <div className="vertical-spacer" />

          {!loading && <div className="download-button-container">
            <div className="download-button" onClick={() => downloadApplicantList()}>
              <img src={downloadIcon} alt="download-icon"/>
              <span className="download-text">応募者CSV</span>
            </div>
          </div>}

          <ErrorModal error={this.state.error} handleClose={this.handleErrorClose.bind(this)}
                      showError={this.state.showError}/>
          <FilterBar
            applications={totalApplications}
            currentFilter={this.state.filterBy}
            applicationCount={applicationCount}
            updateURL={this.updateUrl.bind(this)}
            filterState={this.state.filterState}
            setFilterState={this.setFilterState.bind(this)}
            filterTypeSelected={this.state.filterTypeSelected}
            setFilterTypeSelected={this.setFilterTypeSelected.bind(this)}
            checkedCount={this.state.checkedCount}
            setCheckedCount={this.setCheckedCount.bind(this)}
            filterOptions={this.state.filterOptions}
          />
          <div className="application-list">
            {allApplications}
            {!loading && allApplications.length === 0 && <div className="no-applicants-text">
              {noApplicantsMessage[filterBy]}
            </div>}
          </div>
          {this.state.showMessageModal && <SeekerMessageModal confirmAction={this.messageSeeker.bind(this)}
                                                              confirmationText={DashboardTranslations.jobs_page_request_new_slot_confirm_modal_confirmation_message}
                                                              handleClose={this.closeMessageModal.bind(this)}
                                                              nearestStation={this.state.nearestStation}
                                                              headerText={"オンライン面接の方法を入力して下さい"}
                                                              applicationId={this.state.selectedId}
                                                              isOnlineIV={isOnlineIVList[this.state.selectedId]}
                                                              showMessageModal={this.state.showMessageModal}/>}
          {this.state.showCancelInterviewConfirmation &&
              <ConfirmCancelInterviewModal confirmAction={this.cancelInterview.bind(this)}
                                           confirmationText={DashboardTranslations.jobs_page_cancel_interview_confirmation_text}
                                           handleClose={this.closeConfirmation.bind(this)}
                                           warningText={this.state.cancelWarningText}
                                           employerCancelReasons={this.state.jobsOptions.employer_cancel_reasons}
                                           showConfirmationModal={this.state.showCancelInterviewConfirmation}/>}
          <ConfirmModal confirmAction={this.requestUpdateSlot.bind(this)}
                        warningText={this.state.cancelWarningText}
                        confirmationText={''}
                        handleClose={this.closeRequestNewSlotConfirmation.bind(this)}
                        headerText={DashboardTranslations.jobs_page_request_new_slot_confirm_modal_confirmation_message}
                        showConfirmationModal={this.state.showRequestNewSlotConfirmation}/>
          <ConfirmModal confirmAction={() => {
            this.props.history.push('/dashboard/add-interview-times/' + this.state.selectedId)
          }}
                        confirmationText={'面接枠の登録がありません。面接枠を登録してから面接日の変更リクエストを送信してください。'}
                        handleClose={this.closeNoSlotConfirmation.bind(this)}
                        headerText={'面接枠を登録してください！'}
                        cancelButtonText={'キャンセル'}
                        confirmButtonText={'面接枠を登録する'}
                        showConfirmationModal={this.state.showNoSlotConfirmation}/>
          <SuccessModal customTitle={this.state.successTitle}
                        successMessage={this.state.successMessage || DashboardTranslations.jobs_page_feedback_updated_successfully}
                        handleClose={this.handleClose.bind(this)} showSuccess={this.state.showSuccess}/>
          <ScreeningModal
              newStatus={this.state.newScreeningStatus}
              handleClose={this.closeScreening.bind(this)}
              showScreening={this.state.showScreening}
              confirmAction={this.handleScreening.bind(this)}
          />
          <Modal
              open={this.state.showOutcomeModal}
              onClose={() => this.setState({showOutcomeModal: false})}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <div className="outcome-modal-container">
              <div className="outcome-modal-inner">
                <div className="responsive-header" style={{backgroundColor: "#E3E3E3"}}>
                  <div className="login-header-text">
                    {DashboardTranslations.jobs_page_mark_outcome_modal_header}
                  </div>
                  <div className="burger" style={{border: "0.75px solid #0A57BF", borderRadius: "50%"}}
                       onClick={() => this.setState({showOutcomeModal: false})}
                  >
                    <img src={close} alt="close"/>
                  </div>
                </div>
                <form className="form">
                  <select
                      name="hiredStatus"
                      value={this.state.hiredStatus}
                      onChange={(e) => this.handleChange(e)}
                  >
                    {this.state.hiredStatus !== 'SL' &&
                        <option value="">{DashboardTranslations.jobs_page_dropdown_mark_outcome_text}</option>}
                    <option value="SL">採用（選択すると応募者に通知されます。）</option>
                    {this.state.hiredStatus !== 'SL' &&
                        <option value="RJ">不採用（選択すると応募者に通知されます）</option>}
                    {this.state.hiredStatus !== 'SL' && <option value="AB">面接辞退（応募者に通知されません）</option>}
                    <option value="RS">内定辞退（応募者に通知されません）</option>
                  </select>
                  <div style={{color: "red", fontSize: 13, textAlign: "center", padding: 10}}>
                    {this.state.feedback_error}
                  </div>
                  <textarea rows="4" cols="20" value={this.state.seekerFeedback}
                            onChange={(event) => this.handleChange(event)}
                            name="seekerFeedback"
                            placeholder={DashboardTranslations.jobs_page_feedback_about_seeker_placeholder}>
                </textarea>
                  <div style={{color: "red", fontSize: 13, textAlign: "center", padding: 10}}>
                    {this.state.feedback_text_error}
                  </div>
                  <div className="button-container" style={{margin: '14px auto'}}>
                    <button className="btn btn-secondary" onClick={() => this.setState({showOutcomeModal: false})}>
                      {DashboardTranslations.modal_cancel_button_text}
                    </button>
                    <button className="btn btn-primary" onClick={(e) => this.sendFeedback(e)}>
                      {this.props.application && this.props.application.has_provided_feedback ? DashboardTranslations.jobs_page_edit_feedback_button_text : DashboardTranslations.jobs_page_add_feedback_button_text}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>

          {noApplicationModal &&
              <InfoModalNoApplications closeInfoModalNoApplications={this.closeInfoModalNoApplications.bind(this)}/>}
          {firstApplicationModal && <InfoModalFirstApplication
              closeInfoModalFirstApplication={this.closeInfoModalFirstApplication.bind(this)}/>}

        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token,
    joyride: state.joyride,
    user: state.user
  }
};

Applicants = connect(mapStateToProps)(Applicants);

export default Applicants;
