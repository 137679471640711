export const ENV_TYPE = process.env.REACT_APP_ENV; // DEBUG, TESTING, PROD

let apiRoot;
let seekerPath;
let displayLanguage;


switch (ENV_TYPE) {
    case 'TESTING':
        apiRoot = 'https://test-backend.workjapan.jp/';
        seekerPath = 'https://test-backend.workjapan.jp/foreigners-home/'
        displayLanguage = 'Jp';  // En
        break;
    case 'PROD':
        apiRoot = 'https://backend.workjapan.jp/';
        seekerPath = 'https://www.workjapan.jp/foreigners-home/'
        displayLanguage = 'Jp';
        break;
    default:
        apiRoot = 'http://127.0.0.1:8000/';
        seekerPath = 'http://127.0.0.1:8081/'
        displayLanguage = 'Jp';
}

export const API_ROOT = apiRoot;
export const SEEKER_PATH = seekerPath;
export const DISPLAY_LANGUAGE = displayLanguage;
