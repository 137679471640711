import { DISPLAY_LANGUAGE } from './constants'

const EnglishKeys = {

  //Login

  login_button_text: "Login",

  //Forgot Password page

  forgot_password_page_header: "Recover Password",
  forgot_password_form_header: "Did you forget your password?",
  forgot_password_form_description: "We’ll send you an email with the instructions to restore your password.",
  forgot_password_email_label: "Email",
  forgot_password_submit_button_text: "Send reset instructions",

  //Reset Password page
  reset_password_page_header: "企業様向けのサイト",
  looking_for_job_button_text: "Are you looking for a Job? Click here.",
  reset_password_form_header: "Reset Password",
  reset_password_form_description: "We’ll send you an email with the instructions to restore your password.",
  reset_password_new_password_label: "New Password",
  reset_password_confirm_password_label: "Confirm new Password",
  reset_password_submit_button_text: "Update Password",

  //New Registration flow
  register_page_login_description_text: "If you are a registered company, please log in here",
  register_page_header: "New company registration",
  register_page_sub_header: "You can start posting immediately after registering!",
  register_page_submit_button: "Sign up",
  register_page_success_modal_header: "Welcome!",
  register_page_success_modal_sub_header_1: "A confirmation email has been sent to your registered email address.",
  // register_page_success_modal_sub_header_2: "Please press the Verify your email address button in this email.",
  register_page_success_modal_button_text: "Back to top page",

  //Initialize Company
  initialize_company_header: "Welcome to WORK JAPAN!",
  initialize_company_header_success: "Registration has been completed!",
  initialize_company_subheader: "Please register your company information before creating an offer",
  initialize_company_subheader_success: "Registration has been completed!",
  initialize_company_stage_1: "About",
  initialize_company_stage_2: "Loc Info",
  initialize_company_stage_3: "In charge",
  initialize_company_stage_4: "Done",
  initialize_company_form_1_helper_text: "Enter company information and click Next button",
  initialize_company_form_1_company_name_jp_label: "Company Name (Jp)",
  initialize_company_form_1_company_name_en_label: "Company Name (En)",
  initialize_company_form_1_company_name_kt_label: "Company Name (Kt)",
  initialize_company_form_1_company_phone_number_label: "Company Phone Number",

  //Register/ Login page
  register_page_form_header: "Register",
  register_page_password_label: "Password",
  register_page_password_explanatory_text: "※ Password must have minimum 8 characters with at least one capital letter, one special charater",
  register_page_confirm_password_label: "Confirm password",
  register_page_first_name_label: "First Name",
  register_page_last_name_label: "Last Name",
  register_page_want_to_recieve_notification_text: "I want to receive notifications by SMS. (Notifications will help communicate with applicants smoothly)",
  register_page_mobile_number_label: "Mobile Number (Optional)",
  register_page_submit_button_text: "Register",
  register_page_email_label: "Email",
  register_page_company_phone_number_label: "Company Phone Number",
  register_page_company_name_jp_label: "Company Name(Jp)",
  register_page_company_name_en_label: "Company Name(En)",
  register_page_is_dispatch_agency_label: "Are you a dispatch agency?",
  register_page_industry_type_label: "Industry Type",
  register_page_postcode_label: "Postcode (6 or 7 digits)",
  register_page_prefecture_label: "Prefecture",
  register_page_city1_label: "City 1",
  register_page_city2_label: "City 2",
  register_page_lot_no_label: "Lot No",
  register_page_building_name_jp_label: "Building Name(Jp)",
  register_page_building_name_en_label: "Building Name(En)",
  register_page_email_error_message: "Please enter a valid email address",
  register_page_company_phone_number_error_message: "Please enter a valid phone number",
  register_page_company_name_en_error_message: "Please enter a valid Company Name(En)",
  register_page_company_name_jp_error_message: "Please enter a valid Company Name(Jp)",
  register_page_password_error_message: "Please enter a valid Password",
  register_page_lot_no_error_message: "Please enter a valid Lot No",
  register_page_agree_to_policy_error_message: "Please view the privacy policy and check the button to confirm",
  register_page_first_name_error_message: "Please enter a valid first name",
  register_page_last_name_error_message: "Please enter a valid last name",
  register_page_postcode_error_message: "Please enter a valid postcode",
  register_page_prefecture_error_message: "Please enter a valid prefecture",
  register_page_city1_error_message: "Please select a valid city 1",
  register_page_city2_error_message: "Please select a valid city 2",
  register_page_industry_type_error_message: "Please select a valid industry type",
  register_page_industry_other_label: "Other",
  register_page_industry_other_error_message: "Please enter value for other",
  agree_to_privacy_policy_error_message: "Please agree to the privacy policy",
  register_page_email_explanation: "If you do not receive the email, please check your spam folder",
  register_page_registration_success_message: "Thank you for registration. We sent you an email verification link to your email. Please open your mailbox and click the verification link. You cannot use our service without verifying your email. NOTE: YOU MAY WANT TO CHECK YOUR SPAM FOLDER TOO.",
  register_page_email_verification_success_message: "Thank you for verifying your email. You may now log in and use our services.",
  register_page_email_verification_error_message: "Sorry, we couldn't verify your email. Possible reasons: you have already clicked the link earlier and thus verified your email already.",
  register_page_company_name_en_tooltip_text: "For foreigners to understand we are asking for EN entry for this field",
  register_page_building_name_en_tooltip_text: "For foreigners to understand we are asking for EN entry for this field",
  register_page_invited_user_register_success_message: "User created successfully. You can now login to the dashboard.",
  register_page_passwords_do_not_match_error_message: "The passwords do not match",
  register_page_found_through_label: "Found Through",
  register_page_found_through_error_message: "Select found through",
  register_page_found_through_other_label: "Found Through Other",
  register_page_found_through_other_error_message: "Please enter a valid found through other",
  register_page_company_name_kt_label: "Company Name KT",
  register_page_company_name_kt_error_message: "Please enter a valid Company Name KT",
  register_page_ceo_first_name_label: "CEO First Name",
  register_page_ceo_first_name_error_message: "Please enter a valid CEO First Name",
  register_page_ceo_last_name_label: "CEO Last Name",
  register_page_ceo_last_name_error_message: "Please enter a valid CEO Last Name",
  register_page_ceo_first_name_kt_label: "CEO First Name KT",
  register_page_ceo_first_name_kt_error_message: "Please enter a valid CEO First Name KT",
  register_page_ceo_last_name_kt_label: "CEO Last Name KT",
  register_page_ceo_last_name_kt_error_message: "Please enter a valid CEO Last Name KT",
  register_page_admin_first_name_kt_label: "Admin First Name KT",
  register_page_admin_first_name_kt_error_message: "Please enter a valid Admin First Name KT",
  register_page_admin_last_name_kt_label: "Admin Last Name KT",
  register_page_admin_last_name_kt_error_message: "Please enter a valid Admin Last Name KT",


  //Common
  success_modal_header_text: "Success",
  modal_cancel_button_text: "Cancel",
  error_modal_header_text: "Error",
  info_modal_header_text: "Info",
  default_select_placeholder: "Select",
  confirm_modal_default_text: "Are you sure?",
  confirm_modal_header_text: "Confirmation",
  confirm_modal_confirm_button_text: "Confirm",
  confirm_modal_cancel_button_text: "Cancel",
  submit_button_error_message: "Please enter all the required fields",
  field_mandatory_text: "Req",
  field_optional_text: "Opt",
  error_message_for_valid_mobile: "You can not register with the prefix (050). Please try with another Mobile Number, or contact info@workjapan.jp for help.",
  not_applicable_text: "NA",

  // Add/ Edit Location page

  add_location_brand_name_en_label: "Brand Name(EN)",
  add_location_brand_name_en_error_message: "Please enter a valid brand name",
  add_location_brand_name_jp_label: "Brand Name(JP)",
  add_location_brand_name_jp_error_message: "Please enter a valid brand name",
  add_location_branch_name_label: "Branch Name",
  add_location_branch_name_error_message: "Please enter a valid branch name",
  add_location_postcode_label: "Post code",
  add_location_postcode_error_message: "Please enter a valid post code",
  add_location_prefecture_label: "Prefecture",
  add_location_prefecture_error_message: "Please enter a valid prefecture",
  add_location_city1_label: "City1",
  add_location_city1_error_message: "Please enter a valid city1",
  add_location_city2_label: "City2",
  add_location_city2_error_message: "Please enter a valid city2",
  add_location_lot_no_label: "Lot No",
  add_location_lot_no_error_message: "Please enter a valid lot_no",
  add_location_building_name_jp_label: "Building Name(JP)",
  add_location_building_name_jp_error_message: "Please enter a valid building name(JP)",
  add_location_building_name_en_label: "Building Name(EN)",
  add_location_building_name_en_error_message: "Please enter a valid building name(EN)",
  add_location_nearest_station_label: "Nearest Station",
  add_location_nearest_station_error_message: "Please enter a valid nearest station",
  add_location_commute_time_label: "Commute Time",
  add_location_commute_time_error_message: "Please enter a valid commute time",
  add_location_commute_type_label: "Commute Type",
  add_location_commute_type_error_message: "Please enter a valid commute_type",
  add_location_is_FUEI_label: "IS FUEI",
  add_location_is_FUEI_error_message: "Please enter a valid FUEI",
  add_location_phone_number_label: "Phone Number",
  add_location_phone_number_error_message: "Please enter a valid phone_number",
  add_location_is_FUEI_yes: "Yes",
  add_location_is_FUEI_no: "No",
  add_location_previously_saved_location: "Use a previously saved location?",
  add_location_default_placeholder: "Create Location",
  add_location_brand_name_jp_tootlip_text: "Brand Name",
  add_location_brand_name_en_tooltip_text: "Brand Name EN",
  add_location_branch_location_name_tooltip_text: "Location Name",
  add_location_interview_location_tooltip_text: "Interview Location",
  add_location_building_name_en_tooltip_text: "Building Name En",
  add_location_is_FUEI__tooltip_text: "IS FUEI",
  add_location_industry_tooltip_text: "Industry",
  add_location_create_new_location_tooltip_text: "Create Location",
  add_location_brand_name_jp_guidance_text: "求人記事に表示されます。",
  add_location_brand_name_en_guidance_text: "求人記事に表示されます。",
  add_location_interview_location_header_tooltip_text: "勤務地と面接場所が異なる場合は、面接場所の住所を入力してください。",
  add_location_brand_name_jp_special_guidance_text: "THIS IS THE LOCATION THE SEEKER WILL SEE",
  // add_location_phone_number_special_guidance_text: "THIS NUMBER MUST BE ACCESSIBLE BECAUSE SEEEKR MAY CALL",

  //Add/Edit job page
  add_job_edit_heading: "Edit Jobs",
  // add_job_vacancy_label: "Vacancy",
  // add_job_vacancy_error_message: "Please enter a valid vacancy",
  add_job_interview_in_charge_label: "Interview in charge",
  add_job_interview_in_charge_error_message: "Please select an interview in charge",
  add_job_work_label: "Work",
  add_job_work_error_message: "Enter a valid work",
  add_job_salary_min_label: "Salary Min",
  add_job_salary_min_error_message: "Enter a valid salary min",
  add_job_salary_max_label: "Salary Max",
  add_job_salary_max_error_message: "Enter a valid salary max",
  add_job_training_salary_min_label: "Training Salary Min",
  add_job_training_salary_min_error_message: "Enter a valid training salary min",
  add_job_training_salary_max_label: "Training Salary Max",
  add_job_training_salary_max_error_message: "Enter a valid training salary max",
  add_job_training_duration_label: "Training Duration",
  add_job_training_duration_error_message: "Enter a valid training duration",
  add_job_prefered_experience_label: "Prefered Experience",
  add_job_prefered_experience_error_message: "Enter a valid prefered experience",
  add_job_start_time_label: "Start Time",
  add_job_start_time_error_message: "Enter a valid start time",
  add_job_end_time_label: "End Time",
  add_job_end_time_error_message: "Enter a valid end time",
  add_job_page_work_location_heading: "Work Location",
  add_job_page_interview_location_heading: "Interview Location",
  add_job_page_add_job_section_heading: "Add Job",
  add_job_page_convinient_times_heading: "Convenient Interview Times(Opt)",
  add_job_page_location_same_description: "Interview location same as work location",
  add_job_page_main_heading: "Create New Job",
  add_job_page_address_label: "Address",
  add_job_add_shift_error_message: "Please enter at least one shift",
  post_job_button_text: "Post Job",
  view_job_button_text: "View Job",
  add_job_work_hours_heading: "Work hours",
  add_job_add_shift_button_text: "Add Shift",
  add_job_copy_from_label: "Copy From",
  add_job_interview_in_charge_explanatory_text: "Interview in charge",
  add_job_success_message: "Job Added Successfully",
  add_job_minimun_salary_by_prefecture_explanatory_text: "Please add minimum salary by prefecture",
  add_job_sales_merit_heading: "Sales Merit",
  add_job_in_charge_tooltip_text: "Interview In Charge",
  add_job_salary_min_tooltip_text: "Salary Minimum",
  add_job_sales_merit_tooltip_text: "Sales Merit",
  add_job_sales_merit_copy_merit_tooltip_text: "Copy Merit",
  add_job_foreigner_working_tooltip_text: "Foreigners working",
  add_job_no_nihongo_ok_tooltip_text: "No nihongo ok",
  add_job_training_manual_for_foreigner_tooltip_text: "Training manual_for foreigners",
  add_job_lots_of_overtime_tooltip_text: "Lots of overtime",
  add_job_night_shift_tooltip_text: "Night Shift",
  add_job_select_day_error_message: "Please select a particular day",
  add_job_page_create_job_success_message: "The job has been created. You can now add slots for the job. But, you can run the job only once you have added the credit card details.",
  add_job_page_main_description_modal_text: "You can now create job, but you can run the job only when you have added the credit card details",
  add_job_page_work_location_image_heading: "Image of work location",
  add_job_page_work_location_image_sub_heading: "Post photos of your work at a glance and connect them with the right people!",
  add_job_page_custom_sales_merit_label: "Custom Sales Merit",
  add_job_page_hiring_preferrence_heading: "Hiring Preferrence",
  add_job_page_hiring_preferrence_sub_heading: "Please let us know your request. It does not appear in job postings.",
  add_job_page_transportation_covered_label: "Transportation Covered",
  add_job_page_transportation_covered_error_message: "Please select if transportation is covered or not",
  add_job_page_nationality_and_jp_level_heading: "Nationality and Japanese Level",
  add_job_page_preferred_visa_type_label: "Preferred Visa Type",
  add_job_page_preferred_certificate_label: "Preferred Certificates",
  add_job_page_preferred_custom_certificate_label: "Custom Preferred Certificates",
  add_job_page_preferred_experiences_label: "Preferred Experiences",
  add_job_page_preferred_custom_experiences_label: "Custom Preferred Experiences",
  add_job_page_preferred_quality_and_volume_label: "Preferred Quality or Volume",
  add_job_page_preferred_gender_label: "Preferred Gender",
  add_job_page_nationality_label: "Nationality",
  add_job_page_jp_level_label: "Jp Level",
  add_job_page_add_nationality_and_jp_level_button_text: "Add nationality and JP Level",
  add_job_page_choose_image_button_text: "Choose",
  add_job_page_choose_image_description_text: "Max 5 images",
  add_job_page_dropdown_volume_label: "Volume",
  add_job_page_dropdown_quality_label: "Quality",
  add_job_page_transportation_covered_yes: "Yes",
  add_job_page_transportation_covered_no: "No",
  add_job_page_custom_sales_merit_placeholder_text: "Custom Sales Merit",
  add_job_page_custom_preferred_certificates_placeholder_text: "Custom certificates",
  add_job_page_custom_preferred_experiences_placeholder_text: "Custom Experiences",
  add_job_image_upload_exceed_maximum_number_error_message: "Max 5 images only",
  add_job_nationality_exceed_maximum_error_message: "Max 5 nationality only",
  add_jobs_page_preview_jobs_description: "Preview Job",
  add_job_work_images_not_added_error: "仕事内容が伝わる写真を掲載しましょう！ \n 掲載数（5枚まで）が多いほど応募が増えます！",
  add_job_page_main_error_message: "Please check the fields you have entered, there seems to be some error in them",

  //Preview Jobs
  preview_jobs_salary_label: "Salary",
  preview_jobs_work_details_heading: "Work Details",
  preview_jobs_requirements_sub_heading: "Requirements",
  preview_jobs_work_merit_sub_heading: "Work Merits",
  preview_jobs_work_hour_label: "Work Label",
  preview_jobs_work_station_label: "Work Station",
  preview_jobs_work_location_label: "Work Location",
  preview_jobs_interview_station_label: "Interview Station",
  preview_jobs_interview_location_label: "Interview Location",
  preview_jobs_travel_time_to_work_label: "Travel Time to work",
  preview_jobs_travel_time_value_suffix: "mins from home station",
  preview_jobs_hourly_training_salary_label: "Hourly training salary",
  preview_jobs_training_sub_heading: "Training",
  preview_jobs_taining_duration_label: "Duration",

  //Jobs Main page
  jobs_page_main_heading: "Jobs & Interviews",
  jobs_page_main_heading_small_screen: "Jobs",
  jobs_page_create_job_button_text: "+ Create Job",
  jobs_page_view_calendar_button_text: "View Calendar",
  jobs_page_status_created: "Created",
  jobs_page_status_running: "Running",
  jobs_page_status_stopped: "Stopped",
  jobs_page_status_updated: "Updated",
  jobs_page_status_terminated: "Terminated",
  jobs_page_convinient_interview_times_label: "Convenient Interview Times",
  jobs_page_interview_times_button_text: "+ Interview Times",
  jobs_page_job_details_view_button_label: "View Details",
  jobs_page_job_details_edit_button_label: "Edit Details",
  jobs_page_copy_label: "Copy",
  jobs_page_interview_location_label: "Interview Location",
  jobs_page_interviewer_label: "Interviewer",
  jobs_page_reschedule_interview_button: "Reschedule Interview",
  jobs_page_fixed_interview_text: "Fixed Interview",
  jobs_page_fixed_interview_2_days_left_text: "面接まであと48時間を切りました",
  jobs_page_fixed_interview_1_hour_left_text: "まもなく面接の時間です！",
  jobs_page_cancelled_by_applicant_text: "Cancelled by Applicant",
  jobs_page_add_interview_times_button_text: "+ Interview Times",
  jobs_page_requesting_interview_times_text: "Requesting Interview Time",
  jobs_page_time_request_accepted_text: "Time request accepted",
  jobs_page_reschedule_requested_text: "Reschedule requested",
  jobs_page_dropdown_mark_outcome_text: "Mark Outcome",
  jobs_page_dropdown_hired_text: "Hired",
  jobs_page_dropdown_absent_text: "Absent",
  jobs_page_dropdown_rejected_text: "Rejected",
  jobs_page_dropdown_rejected_by_seeker_text: "Rejected by seeker",
  jobs_page_completed_text: "Completed",
  jobs_page_home_station_text: "Home Station",
  jobs_page_experience_text: "Experience",
  jobs_page_age_text: "Age",
  jobs_page_gender_text: "Gender",
  jobs_page_gender_male_text: "Male",
  jobs_page_gender_female_text: "Female",
  jobs_page_visa_text: "Visa",
  jobs_page_create_first_job_message: "Create your first job post by clicking the button below!",
  jobs_page_no_applications_text: "Applications for interviews will be displayed here. No applications thus far.",
  jobs_page_add_payment_text_user: "You are not allowed to run the job. Please contact the admin to add the payment details",
  // jobs_page_payment_required_error_text: "We could not renew your subscription automatically. Please add your card if you have not yet, or update your card details. Your jobs are stopped right now and you will be able to run them only after renewing your subscription.",
  jobs_page_stop_job_confirmation_message: "Are you sure you want to stop this job?",
  jobs_page_delete_button_tooltip_text: "You cannot delete a job that has beeen started",
  jobs_page_job_status_tooltip_text: "To stop a job, toggle to Stop",
  jobs_page_interview_in_charge_tooltip_text: "Interview In charge",
  jobs_page_slot_remaining_status_tooltip_text: "Slot Remaining Status",
  jobs_page_job_cell_interview_times_tooltip_text: "Interview Times",
  jobs_page_job_cell_employer_reschedule_request_tooltip_text: "Reschedule request",
  jobs_page_mark_outcome_tooltip_text: "Mark Outcome",
  jobs_page_new_slot_requested_by_seeker_tooltip_text: "New slot requested by seeker",
  jobs_page_status_updated_tooltip_text: "記事を掲載するには、「掲載中」にしてください。",
  jobs_page_status_running_tooltip_text: "掲載を停止する場合は、「停止中」にしてください。",
  jobs_page_status_stopped_tooltip_text: "記事を掲載するには、「掲載中」にしてください。",
  jobs_page_feedback_updated_successfully: "Feedback updated succesfully",
  jobs_page_mark_outcome_modal_header: "Mark Outcome",
  jobs_page_mark_outcome_modal_description: "How was the interview? Mark outcome and let us know your feedback!!",
  jobs_page_mark_outcome_field_label: "Mark Outcome",
  jobs_page_feedback_about_seeker_placeholder: "Let us know if you have any commets about the seeker",
  jobs_page_add_feedback_button_text: "Add Feedback",
  jobs_page_edit_feedback_button_text: "Edit Feedback",
  jobs_page_applicant_cell_mark_outcome_button_text: "Mark the Outcome",
  // jobs_page_run_job_error_message: "Your Trial has not started yet. In order to start it, please add a CC.",
  jobs_page_start_running_job_modal_text: "You can now start running the jobs by changing the status",
  jobs_page_cancelled_by_employer_text: "Cancelled by employer",
  jobs_page_cancel_interview_button_text: "Cancel Interview",
  jobs_page_cancel_interview_confirmation_text: "Are you sure you want to cancel the interview? If so, please select a reason for cancellation",
  jobs_page_cancel_interview_reason_error_message: "Please select a valid reason for cancelling the interview",
  jobs_page_cancel_interview_reason_label: "Reason for cancelling",
  jobs_page_cancel_interview_other_error_message: "Please enter a value since you have chosen reason as other",
  jobs_page_cancel_interview_reason_other_label: "Other",
  jobs_page_cancelled_by_employer_tooltip_text: "面接日の2日前までキャンセルができます。",
  jobs_page_new_slot_request_by_employer_tooltip_text: "面接時間の24時間前まで変更依頼ができます。応募者が新しい面接枠を選択すると変更が完了しますが、変更依頼は応募者の離脱に繋がる可能性が高いので、ご注意ください。",
  jobs_page_request_new_slot_confirm_modal_header_text: "注意",
  jobs_page_request_new_slot_confirm_modal_confirmation_message: "面接日の変更を依頼しますか？",
  jobs_page_show_more_button_text: "Show More",
  jobs_page_show_less_button_text: "Show Less",
  jobs_page_view_job_details_tooltip_text: "View Job details",
  jobs_page_edit_job_details_tooltip_text: "Edit Job details",
  jobs_page_edit_job_button_text: "Edit job",
  jobs_page_view_job_button_text: "View Job",
  jobs_page_copy_job_button_text: "Copy Job",

  // Sidebar

  sidebar_jobs_text: "Jobs & Interviews",
  sidebar_teams_text: "Teams",
  sidebar_applicants_text: "Applicants",
  sidebar_billing_text: "Billing",
  sidebar_company_profile_text: "Company Profile",
  sidebar_user_settings_text: "User Settings",
  sidebar_main_settings_text: "Settings",
  sidebar_employer_tips_text: "Employer Tips",
  sidebar_logout_text: "Logout",

  //Add Interview Times Page
  add_interview_times_page_header: "Interview Times",
  add_interview_times_page_description: "Provide a few convenient date & time to conduct interviews for:",
  add_interview_times_page_start_time_label: "Start Time",
  add_interview_times_page_end_time_label: "End Time",
  add_interview_times_page_add_more_time_button_text: "+ Add more",
  add_interview_times_page_submit_button_text: "Submit",
  add_interview_times_redirect_description: "Even if the interview slot have not been added, the job will be posted, but in that case the number of applications may be reduced.",
  add_interview_times_select_date_and_time_error_message: "Please select a date and time",
  add_interview_times_slot_already_present_error_message: "Slot already added",
  add_interview_times_new_slot_text: "New!",
  add_interview_times_no_slots_added_yet: "No Slots have been added yet",

  // Calendar common
  calendar_toolbar_today: "Today",
  calendar_toolbar_next_day: ">",
  calendar_toolbar_prev_day: "<",
  calendar_toolbar_day: "Day",
  calendar_toolbar_week: "Week",

  // Applicant Details
  applicant_page_main_header: "Applicant CV",
  applicant_details_company_name: "Name",
  // applicant_details_interview_status: "Interview Status",
  applicant_details_how_was_interview_label: "How was the interview?",
  applicant_details_how_was_interview_explanatory_text: "The outcome won't be shared with the applicant",
  applicant_details_interview_date_and_time: "Interview Date and Time",
  applicant_details_interviewer: "Interviewer",
  applicant_details_interview_for: "Interview For",
  applicant_details_interview_location: "Interview Location",
  applicant_details_bio_header: "Bio",
  applicant_details_home_station: "Home station",
  applicant_details_nationality: "Nationality",
  applicant_details_age: "Age",
  applicant_details_gender: "Gender",
  applicant_details_japan_stay_duration: "Japan stay duration",
  applicant_details_visa_type: "Visa Type",
  applicant_details_qualifications_header: "Qualifications",
  applicant_details_jp_level: "JP Level",
  applicant_details_jlpt_score: "JLPT Score",
  applicant_details_english_level: "English Level",
  applicant_details_qualifications: "Qualifications",
  applicant_page_education_level_null: "NA",
  applicant_profile_education_level_elementary: "Elementary",
  applicant_profile_education_level_junior_high_school: "Junior High School",
  applicant_profile_education_level_high_school: "High School",
  applicant_profile_education_level_vocational: "Vocational",
  applicant_profile_education_level_university: "University",
  applicant_profile_education_level_other: "Other",
  applicant_details_mon_fri: "Mon-Fri",
  applicant_details_sat_sun: "Sat-Sun",
  applicant_details_interest_header: "Interest/Experience",
  applicant_details_reschedule_requested_note: "NOTE: We have requested the applicant to reschedule the interview. Now the applicant must pick a new interview time to take things further.",
  applicant_details_time_request_accepted_note: "NOTE: You have added convenient interview times and accepted applicant’s request. Now the applicant must pick a specific time to take things further.",
  applicant_details_requesting_interview_times_note: "NOTE: Applicant has expressed in interviewing with you and is requesting you to provide a few convenient times at which the interview may be conducted.",
  applicant_details_mark_outcome_tooltip_text: "Mark Outcome",
  applicant_details_skill_label : "Skill",
  applicant_details_custom_skill_label : "Custom Skill",
  applicant_details_certificates_label : "Certificates",
  applicant_details_other_languages_label : "Other Languages",

  //Company Profile
  profile_page_main_heading: "Company Profile",
  company_details_name_english_label: "Name (English)",
  company_details_name_english_error_message: "Please enter a valid Company Name (English).",
  company_details_name_japanese_label: "Name (Japanese)",
  company_details_name_japanese_error_message: "Please enter a valid Company Name (Japanese).",
  company_details_number_of_employees_label: "No. of Employees",
  company_details_number_of_employees_error_message: "Please select the number of employees",
  company_details_number_of_work_location_label: "No. of Work Locations",
  company_details_number_of_work_location_error_message: "Please select the number of work locations",
  company_details_website_label: "Website",
  company_details_website_error_message: "Please enter a valid website",
  company_details_industry_label: "Industry",
  company_details_industry_error_message: "Please select an industry",
  company_details_dispatch_agency_label: "Do you source hiring for other clients?",
  company_details_phone_number_label: "Phone Number",
  company_details_phone_number_error_message: "Please enter a valid Phone Number",
  address_details_postal_code_label: "Postal Code",
  address_details_postal_code_error_message: "Postal Code not provided.",
  address_details_prefecture_label: "Prefecture",
  address_details_prefecture_error_message: "Prefecture not provided",
  address_details_city1_label: "City (1)",
  address_details_city1_error_message: "Please select city (1)",
  address_details_city2_label: "City (2)",
  address_details_city2_error_message: "Please select city (2)",
  address_details_lot_number_label: "Lot No.",
  address_details_lot_number_error_message: "Please enter a valid Lot No.",
  address_details_building_name_japanese_label: "Building Name (JP)",
  address_details_building_name_label: "Building Name (EN)",
  employees_details_number_hired_label: "No of foreign employees we hired",
  employees_details_japanese_fluency_label: "Non Fluent Japanese speakers OK?",
  employees_details_age_group_label: "Age group we want to hire",
  employees_details_nationalities_label: "Nationalities of people we have hired",
  company_profile_optional_fields_tooltip_text: "Optional",
  company_profile_company_name_en_tooltip_text: "Name EN",
  company_profile_building_name_en_tooltip_text: "Building Name EN",
  company_profile_edit_company_details_button_text: "Edit",
  company_details_company_name_kt_label: "Company Name KT",
  company_details_company_name_kt_error_message: "Please enter a valid Company Name KT",
  company_details_ceo_first_name_label: "CEO First Name",
  company_details_ceo_first_name_error_message: "Please enter a valid CEO First Name",
  company_details_ceo_last_name_label: "CEO Last Name",
  company_details_ceo_last_name_error_message: "Please enter a valid CEO Last Name",
  company_details_ceo_first_name_kt_label: "CEO First Name KT",
  company_details_ceo_first_name_kt_error_message: "Please enter a valid CEO First Name KT",
  company_details_ceo_last_name_kt_label: "CEO Last Name KT",
  company_details_ceo_last_name_kt_error_message: "Please enter a valid CEO Last Name KT",
  company_details_admin_first_name_kt_label: "Admin First Name KT",
  company_details_admin_first_name_kt_error_message: "Please enter a valid Admin First Name KT",
  company_details_admin_last_name_kt_label: "Admin Last Name KT",
  company_details_admin_last_name_kt_error_message: "Please enter a valid Admin Last Name KT",

  // Team Page
  team_page_heading: "Team",
  team_page_add_member: "Add Member",
  team_page_email_label: "Email",
  team_page_role_label: "Role",
  team_page_admin_value: "Admin",
  team_page_member_value: "Member",
  team_page_add_member_submit_button: "Submit",
  team_page_add_member_cancel_button: "Cancel",
  register_page_add_member_success_message: "メンバーを登録しました。",
  invite_register_page_add_member_success_message: "新しいメンバーに登録されました",
  team_page_add_member_success_message: "Member added successfully",
  team_page_add_member_email_already_present_message: "Email already present",
  team_page_invited_email_header: "Invited Email",

  // Interview Times
  interview_times_header: "Interview Times",
  interview_times_add_slot_button_text: "+ Times",
  interview_times_view_caledar_for_text: "View Calendar for :",
  interview_times_arrange_by_text: "Arrange by :",
  interview_times_arrange_by_location_text: "Location",
  interview_times_arrange_by_interviewer_text: "Interviewer",
  interview_times_arrange_by_job_text: "Job",
  interview_times_delete_slot_modal_header: "Delete Slot",
  interview_times_delete_slot_modal_description: "Are you sure you want to delete this slot?",
  interview_times_delete_slot_modal_cancel_button_text: "Cancel",
  interview_times_delete_slot_modal_delete_button_text: "Delete",
  interview_times_delete_slot_success_message: "Slot has been deleted successfully",
  interview_times_date_change_requested_by_seeker: "New slot requested by seeker",
  interview_times_date_change_requested_by_employer: "New slot requested by employer",
  interview_times_new_slot_added_by_employer: "New slot added by employer",
  interview_times_interview_fixed: "Interview Fixed(Awaiting)",
  interview_times_completed: "Completed",
  interview_times_calendar_tooltip_text: "View Calendar",
  interview_times_view_jobs_by_filter_tooltip_text: "View jobs by filter",
  interview_times_interview_date_column_header: "IV Date",
  interview_times_interview_location_column_header: "IV Location",
  interview_times_work_location_column_header: "Work Location",
  interview_times_interviewer_column_header: "Interviewer",
  interview_times_status_column_header: "Status",
  interview_times_applicant_details_column_header: "Applicant",
  interview_times_status_open: "Open",
  interview_times_status_fixed: "IV Fixed",
  interview_times_no_slots_added: "There are no interview slots added.",
  interview_times_date_new_date_to_old_date: "Date Desc",
  interview_times_date_old_date_to_new_date: "Date Asc",
  interview_times_interview_location_ascending: "Interview Location",
  interview_times_work_location_ascending: "Work Location",
  interview_times_interviewer_ascending: "Interviewer",
  interview_times_sort_by_label: "Sort By",

  //User Settings
  user_settings_header_text: "User Settings",
  user_settings_password_header_text: "Password",
  user_settings_first_name_label: "First Name",
  user_settings_first_name_error_message: "Please enter a valid first name",
  user_settings_last_name_label: "Last Name",
  user_settings_last_name_error_message: "Please enter a valid last name",
  user_settings_display_name_label: "Display Name",
  user_settings_display_name_error_message: "Please enter a valid display name",
  user_settings_email_label: "Email",
  user_settings_mobile_label: "Mobile Number",
  user_settings_mobile_error_message: "Please enter a valid mobile number",
  user_settings_current_password_label: "Current Password",
  user_settings_current_password_error_message: "Please enter a valid current password",
  user_settings_new_password_label: "New Password",
  user_settings_new_password_error_message: "Please enter a valid new password",
  user_settings_confirm_password_label: "Confirm Password",
  user_settings_confirm_password_error_message: "Please enter a valid confirm password",
  user_settings_verify_text: "Verify",
  user_settings_save_button_text: "Save",
  user_settings_update_password_text: "Update Password",
  user_settings_verify_mobile_header: "Verify Mobile",
  user_settings_verify_mobile_description: "Verification code was sent to: ",
  user_settings_verification_code_label: "Verification Code",
  user_settings_verification_code_error_message: "Please enter a valid Verification code",
  user_settings_didnt_recieve_code_text: "Didn’t receive the code?",
  user_settings_resend_code_text: "Resend Code",
  user_settings_verify_button_text: "Verify",
  user_settings_cancel_button_text: "Cancel",
  user_settings_password_update_success_message: "Password has been updated successfully",
  user_settings_details_update_success_message: "Details have been updated successfully",
  user_settings_mobile_number_verified_text: "Verified",
  user_profile_mobile_number_verification_explanatory_text: "Enter a valid mobile number",
  user_profile_mobile_number_verified_success_message: "Mobile number verified successfully",
  user_settings_page_description: "If you do not receive the email, please check your spam folder. In addition, we will send notices such as interview reservation notices even after article posting starts, Please be careful not to distribute our e-mails as spam ",
  user_settings_mobile_number_tooltip_text: "Mobile Number",
  user_settings_new_password_tooltip_text: "New password",
  user_settings_confirm_password_tooltip_text: "Confirm Password",


  //Billing page
  billing_page_header_text: "Billing",
  billing_page_usage_header_text: "Usage Remaining",
  billing_page_days_remaining: "days remaining",
  billing_page_applications_remaining: "applications remaining",
  billing_page_current_pack_header: "Current Pack",
  billing_page_add_remove_credit_cards_header_before_cc_added: "Edit CC",
  billing_page_add_remove_credit_cards_header_after_cc_added: "Purchase plan",
  billing_page_add_payment_details_description_text: "Please add payment details",
  billing_page_edit_payment_details_description_text: "Payment details already added",
  billing_page_start_stop_header_text: "Start/Stop Subscription",
  billing_page_stop_subscription_button_text: "Cancel Subscription",
  billing_page_start_subscription_button_text: "Start Subscription",
  billing_page_transactions_header: "Transactions",
  billing_page_transactions_redirect_button_text: "View Transaction History",
  billing_page_cancel_subscription_confirm_modal_text: "Are you sure that you want to cancel the subscription?",
  billing_page_redirect_to_payments_confirm_modal_text: "You will now be redirected to payment gateway. Are you sure that you want to proceed?",
  // billing_page_change_pack_button_text: "Change Plan",
  billing_page_terms_redirect_button_text: "Terms",
  select_previous_payment_method_page_title: "Select Previous Payment Method",
  billing_page_please_contact_admin_text: "You do not have permission to view this page. Please contact the admin",
  billing_page_subscription_cancelled_success_message: "You have succesfully cancelled your subscription. You can resubscribe again from this page",
  billing_page_subscription_renewed_failed_at_gmo_error_message: "Thank you for resubscribing. However, we could not succesfully renewed your subscription. Please add / edit your CC information.",
  billing_page_subscription_renewed_automatically_at_expiry_message: "Thank you for resubscribing, we will renew your subscription automatically once your current subscription expires.",
  billing_page_not_admin_error_message: "You do not have the permission to view this page. Please contact the administrator",
  billing_page_resubscribe_modal_confirmation_text: "Are you sure you want to resubscribe?",
  billing_page_automatic_renewal_failed_message: "We could not renew your subscription automatically. Please add your card if you have not yet, or update your card details. Your jobs are stopped right now and you will be able to run them only after renewing your subscription.",
  billing_page_have_cancelled_but_in_cycle_resubscribe_again_in_basic_plan_message: "You have cancelled your subscription. All your job posts will stop running when your current subscription expires. In order to keep your job running, please resubscribe",
  billing_page_have_cancelled_but_in_cycle_resubscribe_again_in_trial_plan_message: "You have cancelled your subscription. All your job posts will stop running when your current subscription expires. In order to keep your job running, please resubscribe",
  billing_page_resubscribe_in_basic_plan_button_text: "Resubscribe",
  billing_page_resubscribe_in_trial_plan_button_text: "Resubscribe",
  billing_page_renew_subscription_button_text: "Renew Subscription",
  billing_page_auto_renewal_not_done_because_no_running_job: "Your subscription has been  expired but we didn't renew it automatically as you didn't have any running jobs when your subscription expired. If you want to run any job, please renew your subscription first",
  billing_page_no_running_job_and_no_auto_renewal_message: "As you don't have any running job, we will not renew your subscription automatically when the current one expires.",
  billing_page_days_remaining_tooltip_text: "Days Remaining",
  billing_page_trial_plan_payment_without_auto_renewal_success_message: "Thanks for purchasing trial plan. You can now go to jobs page and run the jobs. Your trial plan will be renewed to basic plan after 28 days unless you unsubscribe.",
  billing_page_trial_plan_payment_failed_message: "We could not charge your card. Please add another card.",
  billing_page_start_stop_header_tooltip_text: "掲載期間終了後も掲載が停止しないよう、次のプランが自動的に購入される設定になっております。次プランを購入しない場合は、次プラン購入までに、継続掲載を停止してください。現在のプランの掲載期間が終了すると、掲載が停止します。",

  //Payment Terms
  payment_terms_page_header_text: "Terms and Conditions",
  payment_terms_page_agree_to_terms_error_message: "Please agree to terms and conditions",
  payment_terms_page_add_card_button: "Add Card",
  payment_terms_page_pay_by_cc_button_text: "Pay by CC",
  payment_terms_page_pay_by_invoice_button_text: "Pay by Invoice",
  billing_page_invoice_payment_under_verification_text: "We are currently verifying your details. We will get back to you by the earliest",
  billing_page_invoice_payment_verified_text: "We have verified your details. You can start running jobs now.",
  billing_page_invoice_payment_rejected_text: "We were not able to verify your details. So, please add a credit card to start running jobs",
  billing_page_invoice_payment_under_verification_tooltip: "審査中",
  billing_page_invoice_payment_verified_tooltip: "審査完了",
  billing_page_invoice_payment_rejected_tooltip: "審査が通りませんでした",

  //Transaction Page
  transaction_page_transaction_id_text: "Transaction Id",
  transaction_page_basic_amount_text: "Amount",
  transaction_page_tax_amount_text: "Tax",
  transaction_page_total_amount_text: "Total",
  transaction_page_start_stop_header_text: "Duration",
  transaction_page_event_header: "Event",
  transaction_page_max_applicants_reached_text: "Reached max application",
  transaction_page_max_days_reached_text: "Plan Duration Completed",
  transaction_page_header_text: "Transaction History",
  transaction_page_no_transaction_history_available_text: "No transaction history available right now",
  transaction_page_transaction_time_label: "Transaction Time",
  transaction_page_guidance_text: "Transaction History",
  transaction_page_trial_plan_text: "Trial plan",

  //Header
  inner_header_before_trial_status: "Before Trial",
  inner_header_in_subscription_status: "In subscription",
  inner_header_in_subscription_message: "Please add card details",
  inner_header_about_to_expire_without_auto_renew_in_basic_plan_message: "Basic plan about to expire without auto renew",
  inner_header_about_to_expire_without_auto_renew_in_trial_plan_message: "Trial plan about to expire without auto renew ",
  inner_header_out_of_subscription_status: "Out of subscription",
  select_plan_page_header_text: 'Select Plan',
  select_job_type_page_header_text: 'Select Job Type',
  select_payment_method_page_header_text: 'Select Payment Method',
  confirm_purchase_page_header_text: 'Confirm Purchase',
  change_payment_method: 'Change Method',
  under_inv_verification_desc: '審査には最大5日かかります、完了次第メールにてご連絡いたします。\n審査通過の通知をもって契約が成立し、サービスご利用開始となります。\n※当社は、株式会社ラクーンフィナンシャルに請求業務を委託しております。請求書払いでのご利用には同社の審査が必要となり、\n同社より連絡が入る場合があります。審査をお待ちの間に記事を作成してください。',
  inv_rejected_desc: '大変申し訳ありませんが、請求書払いでのご利用を受け付けることができません。\nクレジットカードのご利用をご検討ください。',

  view_job_initial_info: '記事を編集する場合は、掲載ステータスをOFFにしてください\n一度作成した記事の「勤務地、面接場所、職種」\nの変更はできません。これらの項目の編集が\n必要な場合は、新規に記事を作成してください。',
  edit_job_initial_info: '一度作成した記事の「勤務地、面接場所、職種」\nの変更はできません。\nこれらの項目の編集が必要な場合は、\n新規に記事を作成してください。',
  save_job_popup_info: 'Save Job Popup Info',
  edit_job_pop_up_info: 'Edit Job Popup Info'
}

const JapaneseKeys = {

  //Login

  login_button_text: "企業様ログインはこちら",

  // Forgot password page
  forgot_password_page_header: "パスワードの再設定",
  forgot_password_form_header: "パスワードを忘れましたか？",
  forgot_password_form_description: "パスワード再設定用のメールを登録された\nアカウントに送信します。",
  forgot_password_email_label: "メールアドレス",
  forgot_password_submit_button_text: "パスワード再設定用のメールを送信する",

  // Reset password page
  reset_password_form_header: "パスワード再設定",
  reset_password_form_description: "パスワード再設定用のメールを登録された\nアカウントに送信します。",
  reset_password_new_password_label: "新規パスワード",
  reset_password_confirm_password_label: "パスワード確認",
  reset_password_submit_button_text: "パスワード変更",

  //New Registration flow
  register_page_login_description_text: "すでに登録済みの企業様はこちらからログインしてください",
  register_page_header: "企業様の新規登録はこちらから",
  register_page_sub_header: "ご登録後すぐに掲載を開始できます！",
  register_page_submit_button: "登録する",
  register_page_success_modal_header: "ようこそ！",
  register_page_success_modal_sub_header_1: "ご登録メールアドレスに確認メールをお送りしました。",
  // register_page_success_modal_sub_header_2: "このメールの中にある「メールアドレスを認証する」ボタンを押してください。",
  register_page_success_modal_button_text: "トップページに戻る",

  //Initialize Company
  initialize_company_header: "WORK JAPANにようこそ！",
  initialize_company_header_success: "登録が完了しました！",
  initialize_company_subheader: "募集記事の作成の前に貴社の情報をご登録ください",
  initialize_company_subheader_success: "登録が完了しました！",
  initialize_company_stage_1: "会社情報",
  initialize_company_stage_2: "代表者様情報",
  initialize_company_stage_3: "担当者様情報",
  initialize_company_stage_4: "完了",
  initialize_company_form_1_helper_text: "会社情報を入力して「次へ」ボタンを押してください",
  initialize_company_form_1_company_name_jp_label: "会社名 (日本語）",
  initialize_company_form_1_company_name_en_label: "会社名 (英語）",
  initialize_company_form_1_company_name_kt_label: "会社名 (カタカナ）",
  initialize_company_form_1_company_phone_number_label: "電話番号 （半角数字、ハイフン無し）",

  //Register/ Login page
  register_page_form_header: "企業様向け登録画面 \n ご登録後、すぐに掲載開始できます！",
  register_page_password_label: "パスワード",
  register_page_password_explanatory_text: "※数字の他、大文字と小文字を最低一つずつ含む８文字以上",
  register_page_confirm_password_label: "パスワード確認",
  register_page_first_name_label: "名",
  register_page_last_name_label: "姓",
  register_page_want_to_recieve_notification_text: "通知SMSを許可します。（通知メールとSMSで応募者とのコミュニケーションをスムーズに進まれます）",
  register_page_mobile_number_label: "携帯番号　(任意)",
  register_page_submit_button_text: "登録",
  register_page_email_label: "メールアドレス",
  register_page_company_phone_number_label: "電話番号 （半角・ハイフン無し）",
  register_page_company_name_jp_label: "会社名",
  register_page_company_name_en_label: "会社名（英語表記）",
  register_page_is_dispatch_agency_label: "貴社は派遣会社ですか？",
  register_page_industry_type_label: "業種",
  register_page_postcode_label: "郵便番号（半角・ハイフン無し）",
  register_page_prefecture_label: "都道府県",
  register_page_city1_label: "住所 (1）",
  register_page_city2_label: "住所 (2)",
  register_page_lot_no_label: "番地（半角数字とハイフン）",
  register_page_building_name_jp_label: "建物名（日本語表記）",
  register_page_building_name_en_label: "建物名（ローマ字表記）",
  register_page_email_error_message: "メールアドレスを正しく入力してください",
  register_page_company_phone_number_error_message: "電話番号を正しく入力してください",
  register_page_company_name_en_error_message: "会社名（英語）を正しく入力してください",
  register_page_company_name_jp_error_message: "会社名（日本語）を正しく入力してください",
  register_page_password_error_message: "パスワードを正しく入力してください",
  register_page_lot_no_error_message: "番地を正しく入力してください",
  register_page_agree_to_policy_error_message: "利用規約に合意してください",
  register_page_first_name_error_message: "担当者様の名（漢字）を正しく入力してください　",
  register_page_last_name_error_message: "担当者様の性（漢字）を正しく入力してください　",
  register_page_postcode_error_message: "郵便番号を正しく入力してください",
  register_page_prefecture_error_message: "都道府県を正しく入力してください",
  register_page_city1_error_message: "市町村名 (1）を正しく入力してください",
  register_page_city2_error_message: "市町村名 (2)を正しく入力してください",
  register_page_industry_type_error_message: "業種を正しく入力してください",
  register_page_industry_other_label: "その他",
  register_page_industry_other_error_message: "業種を入力してください。",
  agree_to_privacy_policy_error_message: "利用規約に合意してください",
  register_page_email_explanation: "メールが届かない場合は、迷惑メールフォルダーをご確認ください。\n また、記事掲載開始後も、面接予約通知などご案内を送信しますので、\n 当社送信メールが迷惑メールに振り分けられないよう、ご注意ください",
  register_page_registration_success_message: "ご登録アドレスに認証メールをお送りしました。メール認証が完了しないと、ログインすることができません。メールが届かない場合は迷惑メールフォルダーをご確認ください。",
  register_page_email_verification_success_message: "メール認証が完了しました。「企業様ログイン」ボタンをクリックして管理画面にログインしましょう。",
  register_page_email_verification_error_message: "メール認証は完了しています。\n ご登録のメールアドレスとパスワードでログインしてください。",
  register_page_company_name_en_tooltip_text: "外国人にも解るようローマ字表記をお願いしています。",
  register_page_building_name_en_tooltip_text: "外国人にも解るようローマ字表記をお願いしています。",
  register_page_invited_user_register_success_message: "ユーザー登録が完了しました。ログインして、記事作成を開始しましょう！",
  register_page_passwords_do_not_match_error_message: "パスワードとパスワード確認は異なります",
  register_page_found_through_label: "外国人求人サイトWORK JAPANを知ったきっかけは何ですか？",
  register_page_found_through_error_message: "外国人求人サイトWORK JAPANを知ったきっかけを選択してください。",
  register_page_found_through_other_label: "その他",
  register_page_found_through_other_error_message: "「その他」を入力してください。",
  register_page_company_name_kt_label: "会社名（カタカナ表記）",
  register_page_company_name_kt_error_message: "会社名（カタカナ）を正しく入力してください",
  register_page_ceo_first_name_label: "代表者（名）",
  register_page_ceo_first_name_error_message: "代表者様の名（漢字）を正しく入力してください　",
  register_page_ceo_last_name_label: "代表者（姓）",
  register_page_ceo_last_name_error_message: "代表者様の性（漢字）を正しく入力してください　",
  register_page_ceo_first_name_kt_label: "代表者（名）カタカナ",
  register_page_ceo_first_name_kt_error_message: "代表者様の名（カタカナ）を正しく入力してください　",
  register_page_ceo_last_name_kt_label: "代表者（姓）カタカナ",
  register_page_ceo_last_name_kt_error_message: "代表者様の性（カタカナ）を正しく入力してください　",
  register_page_admin_first_name_kt_label: "担当者（名）カタカナ",
  register_page_admin_first_name_kt_error_message: "担当者様の名（カタカナ）を正しく入力してください　",
  register_page_admin_last_name_kt_label: "担当者（姓）カタカナ",
  register_page_admin_last_name_kt_error_message: "担当者様の性（カタカナ）を正しく入力してください　",

  // Common
  success_modal_header_text: "成功",
  modal_cancel_button_text: "キャンセル",
  error_modal_header_text: "エラー",
  info_modal_header_text: "情報",
  default_select_placeholder: "選択",
  confirm_modal_default_text: "ご確認ください。",
  confirm_modal_header_text: "注意",
  confirm_modal_confirm_button_text: "確認",
  confirm_modal_cancel_button_text: "キャンセル",
  submit_button_error_message: "すべての必須項目を入力してください",
  field_mandatory_text: "必須",
  field_optional_text: "任意",
  error_message_for_valid_mobile: "(050)で始まる番号の登録はできません。別の携帯番号かを登録してください。不明な場合はinfo@workjapan.jpまで！",
  not_applicable_text: "該当なし",

  // Add/ Edit Location

  add_location_brand_name_en_label: "ブランド名・会社名（英語表記）",
  add_location_brand_name_en_error_message: "ブランド名（英語表記）を正しく入力してください",
  add_location_brand_name_jp_label: "ブランド名・会社名（日本語表記）",
  add_location_brand_name_jp_error_message: "ブランド名（日本語表記）を正しく入力してください",
  add_location_branch_name_label: "店舗名",
  add_location_branch_name_error_message: "店舗名を正しく入力してください",
  add_location_postcode_label: "郵便番号（半角・ハイフン無し）",
  add_location_postcode_error_message: "郵便番号（半角・ハイフン無し）を正しく入力してください",
  add_location_prefecture_label: "都道府県",
  add_location_prefecture_error_message: "都道府県を正しく入力してください",
  add_location_city1_label: "市町村名 (1）",
  add_location_city1_error_message: "市町村名 (1）を正しく入力してください",
  add_location_city2_label: "市町村名 (2）",
  add_location_city2_error_message: "市町村名 (2）を正しく入力してください",
  add_location_lot_no_label: "番地（半角: 数字とハイフン）",
  add_location_lot_no_error_message: "番地（半角: 数字とハイフン）を正しく入力してください",
  add_location_building_name_jp_label: "建物名（日本語表記）",
  add_location_building_name_jp_error_message: "建物名（日本語表記）を正しく入力してください",
  add_location_building_name_en_label: "建物名（ローマ字表記）",
  add_location_building_name_en_error_message: "建物名（ローマ字表記）を正しく入力してください",
  add_location_nearest_station_label: "最寄駅",
  add_location_nearest_station_error_message: "最寄駅を正しく入力してください",
  add_location_commute_time_label: "最寄駅からの時間（分）",
  add_location_commute_time_error_message: "最寄駅からの時間（分）を正しく入力してください",
  add_location_commute_type_label: "最寄駅からの移動手段",
  add_location_commute_type_error_message: "最寄駅からの移動手段を正しく入力してください",
  add_location_is_FUEI_label: "風営法対象職種",
  add_location_is_FUEI_error_message: "風営法対象職種を正しく入力してください",
  add_location_phone_number_label: "電話番号（半角・ハイフン無し）",
  add_location_phone_number_error_message: "電話番号（半角・ハイフン無し）を正しく入力してください",
  add_location_is_FUEI_yes: "該当職種",
  add_location_is_FUEI_no: "対象外",
  add_location_previously_saved_location: "勤務地を新規登録する場合は「ブランド名」より入力開始してください。",
  add_location_default_placeholder: "登録済みの勤務地データを利用する",
  add_location_brand_name_jp_tootlip_text: "求人アプリに表示される 貴社の店舗名やブランド名です。",
  add_location_brand_name_en_tooltip_text: "WORK JAPAN求人アプリは、多言語で展開しております。外国人にも解るよう、ローマ字表記をお願いします。",
  add_location_branch_location_name_tooltip_text: "求人アプリには表示されません。多店舗展開されている企業様の記事管理の便宜上、設定しております。「店舗名・勤務地名」がない企業様も、最寄駅名や、本社・本店など、何らかの値を入力してください。",
  add_location_interview_location_tooltip_text: "求人アプリ上に「勤務地」と「面接場所」を地図表示しています。地図表示がされるよう正確な情報の入力をお願いいたします。",
  add_location_building_name_en_tooltip_text: "求職者に解りやすいよう、ローマ字での記載もお願いします。",
  add_location_is_FUEI__tooltip_text: "清掃や調理などの業務でも、風営法対象業種の施設内での就労が不可の在留資格があるため、該当業種の場合は、必ず選択をお願いします。",
  add_location_industry_tooltip_text: "該当職種がない場合は「サポート？」より、お問い合わせください、今後の追加職種として検討させていただきます。",
  add_location_create_new_location_tooltip_text: "新規勤務地を登録するため、そのまま「勤務地作成」を選択してください。",
  add_location_brand_name_jp_guidance_text: "求人記事に表示されます。",
  add_location_brand_name_en_guidance_text: "求人記事に表示されます。",
  add_location_interview_location_header_tooltip_text: "求職者が迷う事がないよう、住所を正確にご入力下さい。",
  add_location_brand_name_jp_special_guidance_text: "応募者が迷わないよう、GoogleMap表示される住所を入力ください。",
  // add_location_phone_number_special_guidance_text: "面接120分前に応募者に表示されます、常時連絡のつく番号を入力ください。",

  // Add/Edit job
  add_job_edit_heading: "編集",
  // add_job_vacancy_label: "募集人数",
  // add_job_vacancy_error_message: "募集人数を正しく入力してください",
  add_job_interview_in_charge_label: "面接担当者",
  add_job_interview_in_charge_error_message: "面接担当者を正しく入力してください",
  add_job_work_label: "職種",
  add_job_work_error_message: "職種を正しく入力してください",
  add_job_salary_min_label: "最低時給（¥/時間・半角）",
  add_job_salary_min_error_message: "最低賃金以上を入力してください（半角）",
  add_job_salary_max_label: "最大時給（¥/時間・半角）",
  add_job_salary_max_error_message: "最低時給以上を入力してください（半角）",
  add_job_training_salary_min_label: "最低研修時給（¥/時間・半角）",
  add_job_training_salary_min_error_message: "研修最低時給（半角）を正しく入力してください",
  add_job_training_salary_max_label: "最大研修時給（¥/時間・半角）",
  add_job_training_salary_max_error_message: "最大研修時給（半角）を正しく入力してください",
  add_job_training_duration_label: "研修期間/日（研修期間の設定がない場合は入力の必要はありません。）",
  add_job_training_duration_error_message: "研修期間を正しく入力してください",
  add_job_prefered_experience_label: "要望経験",
  add_job_prefered_experience_error_message: "要望経験を正しく入力してください",
  add_job_start_time_label: "勤務時間開始",
  add_job_start_time_error_message: "勤務時間を正しく入力してください",
  add_job_end_time_label: "勤務時間終了",
  add_job_end_time_error_message: "勤務時間を正しく入力してください",
  add_job_page_work_location_heading: "勤務地",
  add_job_page_interview_location_heading: "面接方法",
  add_job_page_add_job_section_heading: "募集内容",
  add_job_page_convinient_times_heading: "面接管理",
  add_job_page_location_same_description: "面接場所と勤務地は一緒",
  add_job_page_main_heading: "記事作成",
  add_job_page_address_label: "住所",
  add_job_add_shift_error_message: "少なくても一つの業務シフトを追加してください。",
  post_job_button_text: "送信",
  view_job_button_text: "掲載中の記事を編集する事はできません。\n 記事ステータスを停止にすると、編集できます。",
  add_job_work_hours_heading: "勤務時間",
  add_job_add_shift_button_text: "登録",
  add_job_copy_from_label: "コピー",
  add_job_interview_in_charge_explanatory_text: "新規の面接担当者を登録する場合は「設定→チーム管理」よりユーザーを招待してください。",
  add_job_success_message: "記事の登録が完了しました。次は、面接枠を登録しましょう。面接枠が登録されていると、応募があり次第、スムーズに面接日を確定することができます。",
  add_job_minimun_salary_by_prefecture_explanatory_text: "県によって最低時給が異なりますので、ご注意ください。",
  add_job_sales_merit_heading: "セールスポイント",
  add_job_in_charge_tooltip_text: "面接予約やキャンセルなど、面接に関連した通知が 登録された方のメールに送信されます。",
  add_job_salary_min_tooltip_text: "地域別の最低賃金以上の金額をご入力ください。",
  add_job_sales_merit_tooltip_text: "選択した項目は求人アプリに表示されます。応募者を誘導するために、できるだけ多く選択をお願いします。",
  add_job_sales_merit_copy_merit_tooltip_text: "ご登録済みの記事がある場合は、その記事のセールスポイントをコピーして表示させる事ができます。",
  add_job_foreigner_working_tooltip_text: "応募の安心ポイントになります。外国人勤務中の場合は、是非選択してください。",
  add_job_no_nihongo_ok_tooltip_text: "応募の安心ポイントになります。",
  add_job_training_manual_for_foreigner_tooltip_text: "応募の安心ポイントになります。是非選択してください。",
  add_job_lots_of_overtime_tooltip_text: "長時間の勤務をしたい求職者もたくさんいます。選択をお薦めします。",
  add_job_night_shift_tooltip_text: "夜勤を希望する求職者もたくさんいます。選択をお薦めします。",
  add_job_select_day_error_message: "業務時間（シフト）の日を選択してください。",
  add_job_page_create_job_success_message: "記事の登録が完了しました！　お支払いが完了すると、掲載開始できます！",
  add_job_page_main_description_modal_text: "記事を作成しましょう！記事を作成して、お支払いを完了すると掲載開始できます。",
  add_job_page_work_location_image_heading: "仕事や職場のイメージ",
  add_job_page_work_location_image_sub_heading: "仕事内容が一目で伝わる写真を掲載して 適正人材の応募につなげましょう！",
  add_job_page_custom_sales_merit_label: "セールスポイント",
  add_job_page_hiring_preferrence_heading: "採用希望条件",
  add_job_page_hiring_preferrence_sub_heading: "ご要望をお聞かせください。求人記事には表示されません。",
  add_job_page_hiring_preferrence_heading_tooltip_text: "適正人材の応募につなげるため、採用に関する希望をお聞かせください。",
  add_job_page_transportation_covered_label: "面接交通費",　　
  add_job_page_transportation_covered_error_message: "面接交通費の支給の有無を選択してください。",
  add_job_page_nationality_and_jp_level_heading: "希望国籍と日本語力",
  add_job_page_preferred_visa_type_label: "希望ビザ（在留資格）タイプ",
  add_job_page_preferred_certificate_label: "希望保有資格",
  add_job_page_preferred_custom_certificate_label: "希望保有資格（翻訳されません、平易な日本語で入力してください。）",　
  add_job_page_preferred_experiences_label: "採用希望経験",
  add_job_page_preferred_custom_experiences_label: "希望経験（翻訳されません、平易な日本語で入力してください。）",
  add_job_page_preferred_quality_and_volume_label: "採用方針について",
  add_job_page_preferred_gender_label: "希望性別",
  add_job_page_nationality_label: "国籍",　
  add_job_page_jp_level_label: "日本語力",
  add_job_page_add_nationality_and_jp_level_button_text: "追加",
  add_job_page_choose_image_button_text: "画像を選択する",
  add_job_page_choose_image_description_text: "上限5枚 : 推奨サイズ1200x900px (横長長方形)",
  add_job_page_dropdown_volume_label: "適正より応募数を重視する",
  add_job_page_dropdown_quality_label: "応募数より適正を重視する",
  add_job_page_transportation_covered_yes: "支給",
  add_job_page_transportation_covered_no: "支給なし",
  add_job_page_custom_sales_merit_placeholder_text: "セールスポイント",
  add_job_page_custom_preferred_certificates_placeholder_text: "資格",
  add_job_page_custom_preferred_experiences_placeholder_text: "経験",
  add_job_image_upload_exceed_maximum_number_error_message: "掲載できる画像は５枚までです。",
  add_job_nationality_exceed_maximum_error_message: "選択は5件までです。",
  add_jobs_page_preview_jobs_description: "記事プレビュー画面",
  add_job_work_images_not_added_error: "仕事内容が伝わる写真を掲載しましょう！ \n 掲載数（5枚まで）が多いほど応募が増えます！",
  add_job_page_main_error_message: "未入力または正しく入力されていない項目があります。",

  //Preview Jobs
  preview_jobs_salary_label: "時給",
  preview_jobs_work_details_heading: "仕事詳細",
  preview_jobs_requirements_sub_heading: "必要な資格",
  preview_jobs_work_merit_sub_heading: "おすすめポイント",
  preview_jobs_work_hour_label: "勤務時間",
  preview_jobs_work_station_label: "勤務地の最寄駅",
  preview_jobs_work_location_label: "勤務地",
  preview_jobs_interview_station_label: "面接会場の最寄駅",
  preview_jobs_interview_location_label: "面接会場",
  preview_jobs_travel_time_to_work_label: "自宅最寄駅から勤務地への時間",
  preview_jobs_travel_time_value_prefix: "最寄駅から",
  preview_jobs_travel_time_value_suffix: " 分",
  preview_jobs_hourly_training_salary_label: "研修期間の時給",
  preview_jobs_training_sub_heading: "研修",
  preview_jobs_taining_duration_label: "期間",

  // Main jobs page
  jobs_page_main_heading: "記事と面接枠管理",
  jobs_page_main_heading_small_screen: "記事と面接枠管理",
  jobs_page_create_job_button_text: "+ 記事作成",
  jobs_page_view_calendar_button_text: "面接カレンダー",
  jobs_page_status_created: "作成中",
  jobs_page_status_running: "掲載しています",
  jobs_page_status_stopped: "掲載を停止しています",
  jobs_page_status_updated: "記事の編集中です",
  jobs_page_status_terminated: "削除中",
  jobs_page_convinient_interview_times_label: "面接管理",
  jobs_page_interview_times_button_text: "面接枠を追加する",
  jobs_page_job_details_view_button_label: "記事詳細",
  jobs_page_job_details_edit_button_label: "記事編集",
  jobs_page_copy_label: "複製",
  jobs_page_interview_location_label: "面接場所",
  jobs_page_interviewer_label: "面接担当者",
  jobs_page_reschedule_interview_button: "面接時間の変更を依頼する",
  jobs_page_fixed_interview_text: "面接が確定しました",
  jobs_page_fixed_interview_2_days_left_text: "面接まであと48時間を切りました",
  jobs_page_fixed_interview_1_hour_left_text: "まもなく面接の時間です！",
  jobs_page_cancelled_by_applicant_text: "応募者が面接をキャンセルしました",
  jobs_page_add_interview_times_button_text: "今すぐ面接枠を追加する",
  jobs_page_requesting_interview_times_text: " 面接枠依頼",
  jobs_page_time_request_accepted_text: "応募者の選択待ち",
  jobs_page_reschedule_requested_text: "応募者に面接時間の変更を依頼しました",
  jobs_page_dropdown_mark_outcome_text: "面接結果",
  jobs_page_dropdown_hired_text: "採用",
  jobs_page_dropdown_absent_text: "面接辞退",
  jobs_page_dropdown_rejected_text: "不採用",
  jobs_page_dropdown_rejected_by_seeker_text: "内定辞退",
  jobs_page_completed_text: "面接日を経過しました",
  jobs_page_home_station_text: "最寄駅",
  jobs_page_experience_text: "職歴",
  jobs_page_age_text: "年齢",
  jobs_page_gender_text: "性別",
  jobs_page_gender_male_text: "男性",
  jobs_page_gender_female_text: "女性",
  jobs_page_visa_text: "ビザタイプ",
  jobs_page_create_first_job_message: "「記事作成」をクリックして、最初の記事を作成しましょう！",
  jobs_page_no_applications_text: "応募があった場合は、このページでお知らせします。",
  jobs_page_add_payment_text_user: "お支払が完了していないため、記事掲載を開始することができません。",
  // jobs_page_payment_required_error_text: "お支払が完了していないため、記事掲載を停止しています。",
  jobs_page_stop_job_confirmation_message: "この記事の掲載を停止しますか？",
  jobs_page_delete_button_tooltip_text: "一度、掲載を開始した記事は、削除する事ができません。",
  jobs_page_job_status_tooltip_text: "掲載を停止する場合は、「停止中」にしてください。",
  jobs_page_interview_in_charge_tooltip_text: "応募や面接予約関連の通知メールが届く担当者です。記事担当者とは別に、面接担当者も面接管理ページより設定できます。",
  jobs_page_slot_remaining_status_tooltip_text: "掲載記事ごとの、面接枠の設定状況です。面接枠がゼロとなったり、残り少なくなると、「不足」と表示されます。面接枠が不足すると応募が入りづらくなります。随時追加をお願いします。",
  jobs_page_job_cell_interview_times_tooltip_text: "面接枠が多い企業に応募が集まる傾向があります。常時、２週間先までの面接枠の登録をお勧めします。",
  jobs_page_job_cell_employer_reschedule_request_tooltip_text: "面接日の変更リクエストができます。応募者が新規枠を選択した際はメールやSMS（携帯登録要）で通知します。 ※企業側から、面接をキャンセルする事はできません、ご了承ください。",
  jobs_page_mark_outcome_tooltip_text: "面接結果を選択してください。※選択結果は応募者に通知されません。",
  jobs_page_new_slot_requested_by_seeker_tooltip_text: "面接日程の変更リクエスト中です。応募者が新規枠を選択した際はメールやSMS（携帯登録要）で通知します。",
  jobs_page_status_updated_tooltip_text: "記事を掲載するには、「掲載中」にしてください。",
  jobs_page_status_running_tooltip_text: "掲載を停止する場合は、「停止中」にしてください。",
  jobs_page_status_stopped_tooltip_text: "記事を掲載するには、「掲載中」にしてください。",
  jobs_page_feedback_updated_successfully: "フィードバックを登録しました。",
  jobs_page_mark_outcome_modal_header: "面接結果を通知する",
  jobs_page_mark_outcome_modal_description: "面接の進捗管理にご利用ください。選択した内容が、応募者に通知されることはありません。",
  jobs_page_mark_outcome_field_label: "面接結果",
  jobs_page_feedback_about_seeker_placeholder: '応募者や面接についてコメントを入力してください。 応募者には通知されません。',  //"応募者か面接プロセスについてコメントがあればここで書いてください。",
  jobs_page_add_feedback_button_text: "登録する",
  jobs_page_edit_feedback_button_text: "更新する",
  jobs_page_applicant_cell_mark_outcome_button_text: "面接の結果を入力する",
  // jobs_page_run_job_error_message: "購入手続きが完了すると掲載を開始できます。",
  jobs_page_start_running_job_modal_text: "記事ステータスを掲載中に変更して、掲載を開始してください。",
  jobs_page_cancelled_by_employer_text: "企業からキャンセル",
  jobs_page_cancel_interview_button_text: "選考不合格を通知する",
  jobs_page_cancel_interview_confirmation_text: "この面接をキャンセルしますか？ キャンセルすると応募者に通知されます。",
  jobs_page_cancel_interview_reason_error_message: "キャンセル理由を選択してください。",
  jobs_page_cancel_interview_reason_label: "キャンセル理由",
  jobs_page_cancel_interview_other_error_message: "キャンセル理由をご記入ください。",
  jobs_page_cancel_interview_reason_other_label: "その他",
  jobs_page_cancelled_by_employer_tooltip_text: "面接日の2日前までキャンセルができます。",
  jobs_page_new_slot_request_by_employer_tooltip_text: "面接時間の24時間前まで変更依頼ができます。応募者が新しい面接枠を選択すると変更が完了しますが、変更依頼は応募者の離脱に繋がる可能性が高いので、ご注意ください。",
  jobs_page_request_new_slot_confirm_modal_header_text: "注意",
  jobs_page_request_new_slot_confirm_modal_confirmation_message: "面接日の変更を依頼しますか？",
  jobs_page_show_more_button_text: "全表示",
  jobs_page_show_less_button_text: "折りたたむ",
  jobs_page_view_job_details_tooltip_text: "作成した記事のプレビュー。記事を編集する場合はステータスを「停止」に変更してください。",
  jobs_page_edit_job_details_tooltip_text: "記事を編集する場合はステータスを「停止」に変更してください。",
  jobs_page_edit_job_button_text: "記事を編集する",
  jobs_page_view_job_button_text: "記事のプレビュー",
  jobs_page_copy_job_button_text: "記事を複製する",

  // Sidebar
  sidebar_jobs_text: "記事と面接枠管理",
  sidebar_teams_text: "チーム管理",
  sidebar_applicants_text: "応募者の管理",
  sidebar_billing_text: "お支払の管理",
  sidebar_company_profile_text: "企業情報",
  sidebar_user_settings_text: "ユーザー設定",
  sidebar_main_settings_text: "設定",
  sidebar_employer_tips_text: "掲載マニュアル",
  sidebar_logout_text: "ログアウト",

  //Add Interview Times Page
  add_interview_times_page_header: "面接枠管理",
  add_interview_times_page_description: "1. 面接枠を登録して,面接時間を選択してください。\n 2.「＋面接枠を登録」ボタンを押してください。\n 3. すべての面接枠を登録してから「送信」ボタンを押してください。\n ※応募者は応募と同時に面接を予約します。\n ※面接枠が登録されていないくても、記事は掲載されますが、その場合は応募数が減る可能性があります。",
  add_interview_times_page_start_time_label: "面接開始時間",
  add_interview_times_page_end_time_label: "面接終了時間",
  add_interview_times_page_add_more_time_button_text: "＋面接枠を登録",
  add_interview_times_page_submit_button_text: "送信",
  add_interview_times_redirect_description: "面接枠が未登録でも、記事掲載は開始しますが、その場合は応募数が減る可能性があります。",
  add_interview_times_select_date_and_time_error_message: "面接枠の日時を正しく選択してください。",
  add_interview_times_slot_already_present_error_message: "一人の面接担当者に重複する面接枠を登録する事はできません。\n 重複する面接枠を登録する場合は、記事作成ページの面接担当者を変更してください。",
  add_interview_times_new_slot_text: "新規追加分",
  add_interview_times_no_slots_added_yet: "面接枠は登録されていません。",

  // Calendar common
  calendar_toolbar_today: "今日",
  calendar_toolbar_next_day: ">",
  calendar_toolbar_prev_day: "<",
  calendar_toolbar_day: "日",
  calendar_toolbar_week: "週",

  // Applicant Details
  applicant_page_main_header: "応募者の詳細",
  applicant_details_company_name: "氏名",
  // applicant_details_interview_status: "面接結果",
  applicant_details_how_was_interview_label: "面接はいかがでした?",
  applicant_details_how_was_interview_explanatory_text: "選択結果は応募者に通知されません。社内管理用にご利用ください。",
  applicant_details_interview_date_and_time: "面接日時",
  applicant_details_interviewer: "面接担当者",
  applicant_details_interview_for: "応募記事",
  applicant_details_interview_location: "面接場所",
  applicant_details_bio_header: "応募者詳細",
  applicant_details_home_station: "最寄駅",
  applicant_details_nationality: "国籍",
  applicant_details_age: "年齢",
  applicant_details_gender: "性別",
  applicant_details_japan_stay_duration: "日本滞在期間",
  applicant_details_visa_type: "ビザタイプ",
  applicant_details_qualifications_header: "学歴や資格",
  applicant_details_jp_level: "日本語会話力",
  applicant_details_jlpt_score: "日本語能力試験",
  applicant_details_english_level: "英会話力",
  applicant_details_qualifications: "最終学歴",
  applicant_page_education_level_null: "NA",
  applicant_profile_education_level_elementary: "小学校",
  applicant_profile_education_level_junior_high_school: "中学校",
  applicant_profile_education_level_high_school: "高校",
  applicant_profile_education_level_vocational: "専門学校",
  applicant_profile_education_level_university: "大学",
  applicant_profile_education_level_other: "他",
  applicant_details_mon_fri: "月ー金",
  applicant_details_sat_sun: "土ー日",
  applicant_details_interest_header: "興味のある・経験のある仕事",
  applicant_details_reschedule_requested_note: "応募者に面接変更依頼をしています。応募者が新たな面接枠を選択すると通知が届きます。",
  applicant_details_time_request_accepted_note: "応募者の依頼を受け、新たな面接枠を提供済みです。応募者が新たな面接枠を選択すると通知が届きます。",
  applicant_details_requesting_interview_times_note: "応募者から新たな面接枠の追加依頼が来ています！応募者を逃さないよう、できるだけ多くの面接枠を登録してください。",
  applicant_details_mark_outcome_tooltip_text: "面接結果を選択してください。※選択結果は応募者に通知されません。",
  applicant_details_skill_label : "スキルl",
  applicant_details_custom_skill_label : "スキル",
  applicant_details_certificates_label : "資格",
  applicant_details_other_languages_label : "他の言語",

  //Company Profile
  profile_page_main_heading: "企業情報",
  company_details_name_english_label: "会社名（英語表記）",
  company_details_name_english_error_message: "会社名（英語）を正しく入力してください。",
  company_details_name_japanese_label: "会社名（日本語表記）",
  company_details_name_japanese_error_message: "会社名（日本語）を正しく入力してください。",
  company_details_number_of_employees_label: "従業数",
  company_details_number_of_employees_error_message: "従業数を正しく入力してください。",
  company_details_number_of_work_location_label: "勤務地数（店舗や営業所など）",
  company_details_number_of_work_location_error_message: "勤務地数（店舗や営業所など）を正しく入力してください。",
  company_details_website_label: "WEBサイト",
  company_details_website_error_message: "WEBサイトを正しく入力してください。",
  company_details_industry_label: "業種",
  company_details_industry_error_message: "業種を正しく入力してください。",
  company_details_dispatch_agency_label: "貴社は派遣会社ですか？",
  company_details_phone_number_label: "電話番号（半角・ハイフン無し）",
  company_details_phone_number_error_message: "電話番号（半角・ハイフン無し）を正しく入力してください。",
  address_details_postal_code_label: "郵便番号（半角・ハイフン無し）",
  address_details_postal_code_error_message: "郵便番号（半角・ハイフン無し）を正しく入力してください。",
  address_details_prefecture_label: "都道府県",
  address_details_prefecture_error_message: "都道府県を正しく入力してください。",
  address_details_city1_label: "市町村名 (1）",
  address_details_city1_error_message: "市町村名 (1）を正しく入力してください。",
  address_details_city2_label: "市町村名 (2)",
  address_details_city2_error_message: "市町村名 (2)を正しく入力してください。",
  address_details_lot_number_label: "番地（半角: 数字とハイフン）",
  address_details_lot_number_error_message: "番地（半角: 数字とハイフン）を正しく入力してください。",
  address_details_building_name_japanese_label: "建物名（日本語表記）",
  address_details_building_name_label: "建物名（ローマ字表記）",
  employees_details_number_hired_label: "在職中の外国人の数",
  employees_details_japanese_fluency_label: "日本語力が低い人も採用する",
  employees_details_age_group_label: "積極的に採用したい年齢層",
  employees_details_nationalities_label: "採用したことがある外国人の国籍",
  company_profile_optional_fields_tooltip_text: "適正な人材を応募に誘導するために、お聞きしている項目です。是非ご回答をお願いします。",
  company_profile_company_name_en_tooltip_text: "外国人にも解るよう、ローマ字表記をお願いしております。",
  company_profile_building_name_en_tooltip_text: "外国人にも解るよう、ローマ字表記をお願いしております。",
  company_profile_edit_company_details_button_text: "編集",
  company_details_company_name_kt_label: "会社名（カタカナ表記）",
  company_details_company_name_kt_error_message: "カタカナで入力してください",
  company_details_ceo_first_name_label: "代表者（名）",
  company_details_ceo_first_name_error_message: "代表者様の名（漢字）を正しく入力してください　",
  company_details_ceo_last_name_label: "代表者（姓）",
  company_details_ceo_last_name_error_message: "代表者様の性（漢字）を正しく入力してください　",
  company_details_ceo_first_name_kt_label: "代表者（名）カタカナ",
  company_details_ceo_first_name_kt_error_message: "カタカナで入力してください",
  company_details_ceo_last_name_kt_label: "代表者（姓）カタカナ",
  company_details_ceo_last_name_kt_error_message: "カタカナで入力してください",
  company_details_admin_first_name_kt_label: "担当者（名）カタカナ",
  company_details_admin_first_name_kt_error_message: "カタカナで入力してください",
  company_details_admin_last_name_kt_label: "担当者（姓）カタカナ",
  company_details_admin_last_name_kt_error_message: "カタカナで入力してください",


  // Team Page
  team_page_heading: "チーム管理",
  team_page_add_member: "ユーザーの追加",
  team_page_email_label: "メールアドレス",
  team_page_role_label: "役割",
  team_page_admin_value: "管理者",
  team_page_member_value: "一般",
  team_page_add_member_submit_button: "送信",
  team_page_add_member_cancel_button: "キャンセル",
  register_page_add_member_success_message: "メンバーを登録しました。",
  invite_register_page_add_member_success_message: "新しいメンバーに登録されました",
  team_page_add_member_success_message: "新しいメンバーを招待しました",
  team_page_add_member_email_already_present_message: "メールアドレスは既に登録されています。",
  team_page_invited_email_header: "招待メールを送信済",

  // Interview Times
  interview_times_header: "面接日時",
  interview_times_add_slot_button_text: "+ 面接枠",
  interview_times_view_caledar_for_text: "カレンダーフィルター：",
  interview_times_arrange_by_text: "フィルター:",
  interview_times_arrange_by_location_text: "場所",
  interview_times_arrange_by_interviewer_text: "面接担当",
  interview_times_arrange_by_job_text: "記事",
  interview_times_delete_slot_modal_header: "面接枠削除",
  interview_times_delete_slot_modal_description: "この面接を削除しますか:",
  interview_times_delete_slot_modal_cancel_button_text: "キャンセル",
  interview_times_delete_slot_modal_delete_button_text: "削除",
  interview_times_delete_slot_success_message: "面接枠を削除しました。",
  interview_times_date_change_requested_by_seeker: "応募者から面接枠追加の依頼が届いています。",
  interview_times_date_change_requested_by_employer: "面接日変更を応募者に依頼しています。",
  interview_times_new_slot_added_by_employer: "面接枠を追加しています。",
  interview_times_interview_fixed: "面接確定",
  interview_times_completed: "面接日経過",
  interview_times_calendar_tooltip_text: "＊面接枠は48時間以降から登録できます。異なる「日付 X 時間帯」の組み合わせを登録する場合は、この画面を一度閉じて、再度追加してください。",
  interview_times_view_jobs_by_filter_tooltip_text: "記事や面接担当者ごとに、面接枠や面接予約状況を見る事ができます。",
  interview_times_interview_date_column_header: "面接日時",
  interview_times_interview_location_column_header: "面接場所",
  interview_times_work_location_column_header: "勤務地",
  interview_times_interviewer_column_header: "面接担当者",
  interview_times_status_column_header: "面接予約状況",
  interview_times_applicant_details_column_header: "応募者",
  interview_times_status_open: "空枠",
  interview_times_status_fixed: "予約済",
  interview_times_no_slots_added: "面接枠は設定されていません。",
  interview_times_date_new_date_to_old_date: "新しい日付順",
  interview_times_date_old_date_to_new_date: "古い日付順",
  interview_times_interview_location_ascending: "面接場所順",
  interview_times_work_location_ascending: "勤務地順",
  interview_times_interviewer_ascending: "面接担当者順",
  interview_times_sort_by_label: "並べ替え",

  //User Settings
  user_settings_header_text: "ユーザー設定",
  user_settings_password_header_text: "パスワード再設定",
  user_settings_first_name_label: "名",
  user_settings_first_name_error_message: "名を正しく入力してください",
  user_settings_last_name_label: "姓",
  user_settings_last_name_error_message: "姓を正しく入力してください",
  user_settings_display_name_label: "表示名",
  user_settings_display_name_error_message: "表示名を正しく入力してください",
  user_settings_email_label: "メールアドレス",
  user_settings_mobile_label: "携帯番号",
  user_settings_mobile_error_message: "携帯番号を正しく入力してください",
  user_settings_current_password_label: "既存パスワード",
  user_settings_current_password_error_message: "を正しく入力してください",
  user_settings_new_password_label: "新規パスワード",
  user_settings_new_password_error_message: "新規パスワードを正しく入力してください",
  user_settings_confirm_password_label: "新規パスワード確認",
  user_settings_confirm_password_error_message: "新規パスワードを正しく入力してください",
  user_settings_verify_text: "携帯番号を認証する",
  user_settings_save_button_text: "保存",
  user_settings_update_password_text: "パスワード更新",
  user_settings_verify_mobile_header: "携帯電話確認",
  user_settings_verify_mobile_description: "確認コードを: ",
  user_settings_verification_code_label: "確認コード",
  user_settings_verification_code_error_message: "確認コードを正しく入力してください",
  user_settings_didnt_recieve_code_text: "確認コードが届いていませんか?",
  user_settings_resend_code_text: "確認コードを再送信",
  user_settings_verify_button_text: "確認",
  user_settings_cancel_button_text: "キャンセル",
  user_settings_password_update_success_message: "パスワードを更新しました。",
  user_settings_details_update_success_message: "ユーザー詳細を更新しました。",
  user_settings_mobile_number_verified_text: "認証済",
  user_profile_mobile_number_verification_explanatory_text: "携帯番号を登録すると、面接予約や応募関連の通知がSMSに届きます。",
  user_profile_mobile_number_verified_success_message: "携帯番号を認証されました。",
  user_settings_page_description: "メールが届かない場合は、迷惑メールフォルダーをご確認ください。 \n また、記事掲載開始後も、面接予約通知などご案内を送信しますので、\n 当社送信メールが迷惑メールに振り分けられないよう、ご注意ください",
  user_settings_mobile_number_tooltip_text: "携帯を登録すると、面接予約などがSMS通知されます。大切な応募者を取りこぼさないよう、是非、ご利用ください。",
  user_settings_new_password_tooltip_text: "※数字の他、大文字と小文字を最低一つずつ含む８文字以上",
  user_settings_confirm_password_tooltip_text: "※数字の他、大文字と小文字を最低一つずつ含む８文字以上",

  //Billing page
  billing_page_header_text: "お支払の管理",
  billing_page_usage_header_text: "お支払の管理",
  billing_page_days_remaining: "日数残り",
  billing_page_applications_remaining: "応募残り",
  billing_page_current_pack_header: "ご利用中のプラン",
  billing_page_add_remove_credit_cards_header_before_cc_added: "プラン購入",
  billing_page_add_remove_credit_cards_header_after_cc_added: "カード情報",
  billing_page_add_payment_details_description_text: "まだ購入していません。",
  billing_page_edit_payment_details_description_text: "クレジットカード情報編集してください。",
  billing_page_start_stop_header_text: "自動更新の開始/停止",
  billing_page_stop_subscription_button_text: "自動更新を停止する",
  billing_page_start_subscription_button_text: "自動更新を開始する",
  billing_page_transactions_header: "購入履歴",
  billing_page_transactions_redirect_button_text: "購入履歴を見る",
  billing_page_cancel_subscription_confirm_modal_text: "自動更新を停止します。ご確認ください。",
  billing_page_redirect_to_payments_confirm_modal_text: "外部の支払いサイトに移動します。ご確認ください。",
  // billing_page_change_pack_button_text: "次のプランを購入する",
  billing_page_terms_redirect_button_text: "料金体系",
  billing_page_please_contact_admin_text: "このページを閲覧する権限がありません。貴社の管理者にご連絡ください。",
  billing_page_subscription_cancelled_success_message: "自動更新を停止しました。現在のプランの利用期限までご掲載いただけます。",
  billing_page_subscription_renewed_failed_at_gmo_error_message: "自動更新の開始を選択いただきましたが、お支払いが完了しておりません。お支払いにお進みください。",
  billing_page_subscription_renewed_automatically_at_expiry_message: "自動更新の開始を選択いただきありがとうございます。現在進行中のプラン終了次第、次のプランに移行します。",
  billing_page_not_admin_error_message: "このページを閲覧する権限がありません。貴社の管理者にご連絡ください。",
  billing_page_resubscribe_modal_confirmation_text: "自動更新を開始しますか?",
  billing_page_automatic_renewal_failed_message: "お支払いが完了していないため、求人記事の掲載が停止しています。掲載を開始するには、お支払いを完了してください。",
  billing_page_have_cancelled_but_in_cycle_resubscribe_again_in_basic_plan_message: "ご利用期間が終了すると掲載を停止します。継続掲載をご希望の場合は、自動更新を開始してください。",
  billing_page_have_cancelled_but_in_cycle_resubscribe_again_in_trial_plan_message: "ご利用期間が終了すると掲載を停止します。継続掲載をご希望の場合は、自動更新を開始してください。",
  billing_page_resubscribe_in_basic_plan_button_text: "自動更新の開始/停止",
  billing_page_resubscribe_in_trial_plan_button_text: "自動更新を開始する",
  billing_page_renew_subscription_button_text: "自動更新",
  billing_page_auto_renewal_not_done_because_no_running_job: "自動更新の開始が選択されましたが、掲載中の記事がないため、自動更新を停止しています。",
  billing_page_no_running_job_and_no_auto_renewal_message: "掲載中の記事がないため、現在のプラン終了後、自動更新を停止します。",
  billing_page_days_remaining_tooltip_text: "掲載期間の終了後、次のプランに移行します。自動更新はいつでも停止することができます。",
  billing_page_trial_plan_payment_without_auto_renewal_success_message: "お支払いが完了しました！",
  billing_page_trial_plan_payment_failed_message: "お支払いが完了していません。お支払いにお進みください。",
  billing_page_start_stop_header_tooltip_text: "自動更新を選択すると、停止することなく掲載を継続できます。直近購入プランの終了前であれば、いつでも自動更新を停止することができます。",

  //Payment Terms
  payment_terms_page_header_text: "料金体系",
  payment_terms_page_agree_to_terms_error_message: "利用規約に合意してください。",
  payment_terms_page_add_card_button: "購入する",
  payment_terms_page_pay_by_cc_button_text: "クレジットカード払い",
  payment_terms_page_pay_by_invoice_button_text: "請求書払い",
  billing_page_invoice_payment_under_verification_text: "『請求書払い・審査中』\n審査には最大5日かかり、審査通過日よりごご利用開始となります。\n※当社は、株式会社ラクーンフィナンシャルに請求業務を委託しており、\nご利用にあたり審査に関するに連絡が入る場合があります。\n審査不通過の場合はご利用できません。クレジットカード払いをご検討ください。",
  billing_page_invoice_payment_verified_text: "審査が通過しましたので掲載開始いただけます。",
  billing_page_invoice_payment_rejected_text: "大変申し訳ありませんが、請求書払いでのご利用を受け付けることができません。クレジットカードのご利用をご検討ください。",
  billing_page_invoice_payment_under_verification_tooltip: "審査中",
  billing_page_invoice_payment_verified_tooltip: "審査完了",
  billing_page_invoice_payment_rejected_tooltip: "審査が通りませんでした",

  //Transaction page
  transaction_page_transaction_id_text: "購入履歴ID",
  transaction_page_basic_amount_text: " プラン料金",
  transaction_page_tax_amount_text: " 消費税",
  transaction_page_total_amount_text: " お支払額",
  transaction_page_start_stop_header_text: "ご利用期間",
  transaction_page_event_header: "プラン消化理由",
  transaction_page_max_applicants_reached_text: "応募件数に達しました。",
  transaction_page_max_days_reached_text: "プランの有効期間が終了しました。",
  transaction_page_header_text: "購入履歴",
  transaction_page_no_transaction_history_available_text: "購入履歴はまだありません。",
  transaction_page_transaction_time_label: "購入日",
  transaction_page_guidance_text: "掲載期間はプランの購入日からカウントされます。プラン購入日と、掲載開始日は異なる場合があります。",
  transaction_page_trial_plan_text: "ご利用中",

  //Header
  inner_header_before_trial_status: "掲載は開始していません",
  inner_header_in_subscription_status: "自動更新選択中",
  inner_header_in_subscription_message: "クレジットカード情報をご登録ください。",
  inner_header_about_to_expire_without_auto_renew_in_basic_plan_message: "自動更新は選択されていません。利用期間終了後、掲載が停止します。",
  inner_header_about_to_expire_without_auto_renew_in_trial_plan_message: "自動更新は選択されていません。利用期間終了後、掲載が停止します。 ",
  inner_header_out_of_subscription_status: "継続掲載停止中",
  select_plan_page_header_text: '料金プランの選択',
  select_job_type_page_header_text: '記事作成',
  select_payment_method_page_header_text: 'お支払方法の選択',
  confirm_purchase_page_header_text: 'ご購入内容',
  select_previous_payment_method_page_title: "お支払方法の変更",
  change_payment_method: 'お支払方法を変更する',
  under_inv_verification_desc: '審査には最大5日かかります。審査通過と同時に契約が成立しご利用開始となります。\n※当社は、株式会社ラクーンフィナンシャルに請求業務を委託しております。請求書払いでのご利用には同社の審査が必要となり、\n同社より連絡が入る場合があります。審査不通過の場合はご利用できません。クレジットカードでのお支払いをご検討ください。',
  inv_rejected_desc: '大変申し訳ありませんが、請求書払いでのご利用を受け付けることができません。\nクレジットカードのご利用をご検討ください。',

  view_job_initial_info: '記事を編集する場合は、掲載ステータスをOFFにしてください\n一度作成した記事の「勤務地、面接場所、職種」\nの変更はできません。これらの項目の編集が\n必要な場合は、新規に記事を作成してください。',
  edit_job_initial_info: '一度作成した記事の「勤務地、面接場所、職種」\nの変更はできません。\nこれらの項目の編集が必要な場合は、\n新規に記事を作成してください。',
  save_job_popup_info: '記事作成途中での保存はできません。\n' +
    'ステップ④の確認ページで送信ボタンを押すまでは、\n' +
    'ブラウザーを閉じないでください。',
  edit_job_pop_up_info: '「勤務地、面接場所、職種」の編集はできません。\n' +
    '編集する場合は新規に記事を作成してください。\n' +
    'また、ステップ④の確認ページで送信ボタンを押すまでは、\n' +
    '記事の保存はできません。'
}

var DashboardTranslations = EnglishKeys
if (DISPLAY_LANGUAGE === "Jp") {
  DashboardTranslations = JapaneseKeys
}

export default DashboardTranslations
