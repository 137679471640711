const daysArray = {
  days: [
    {
      id: "Mon",
      name: "月",
    },
    {
      id: "Tue",
      name: "火",
    },
    {
      id: "Wed",
      name: "水",
    },
    {
      id: "Thu",
      name: "木",
    },
    {
      id: "Fri",
      name: "金",
    },
    {
      id: "Sat",
      name: "土",
    },
    {
      id: "Sun",
      name: "日",
    }
  ],
  englishDays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
}
export default daysArray
