import React, { Component } from "react";
import Switch from "react-switch";
import ApplicantCell from "./ApplicantCell"; // Alert: Don't delete this line
import DashboardTranslations from "../../DashboardTranslations.js";
import { getErrorMessage } from "../../messages/errors";
import { API_ROOT } from "../../constants";
import { connect } from "react-redux";
import {
  updateApplicantStatusAPI,
  updateSlots,
  addPicturesAPI,
  deleteImage,
  job,
  moveImage,
  getSeekerJobData,
} from "../helperFunctions/dataServices.js";
import axios from "axios";
import ErrorModal from "../components/ErrorModal";
import ConfirmModal from "../components/ConfirmModal";
import SuccessModal from "../components/SuccessModal";
import ScaleLoader from "react-spinners/ScaleLoader";
import "react-toggle-switch/dist/css/switch.min.css";
import "./styles/jobCell.scss";
import closeIcon from "../../images/carousel-close.svg";
import moveIcon from "../../images/carousel-move.png";
import alert from "../../images/alert.png";
import videoIcon from "../../images/svg/video-icon-job-list.svg";
import archiveIcon from "../../images/svg/archive-icon.svg";
import archiveIconOn from "../../images/svg/archive-icon-on.svg";
import { Tooltip } from "@material-ui/core";
import SeekerIFrame from "../components/SeekerIFrame.js";

const Compress = require("compressorjs");

class JobCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showError: false,
      error: "",
      statusUpdateLoading: false,
      showJobStopConfirmation: false,
      showSuccess: false,
      paymentError: "",
      showPaymentError: false,
      subscriptionData: props.subscriptionData,
      showStyle: "Less",
      selectedFile: [],
      showImageUploadSuccess: false,
      seekerData: null,
      previewJobOpen: false,
    };
  }

  convertExpiryTimestamp(timestamp) {
    let date = new Date(timestamp * 1000);
    date.setDate(date.getDate() + 10);

    const cDate = date.getDate();
    const cMonth = date.getMonth();
    const cYear = date.getFullYear();

    if (timestamp !== null) {
      return [cYear, "年", cMonth + 1, "月", cDate, "日"].join("");
    } else {
      return "";
    }
  }

  closeConfirmation() {
    this.setState({ showJobStopConfirmation: false });
  }

  styleChange() {
    if (this.state.showStyle === "Less") {
      this.setState({ showStyle: "More" });
    } else {
      this.setState({ showStyle: "Less" });
    }
  }

  async updateStatus(nextStatus) {
    if (this.props.subscriptionData.subscription_status === "BT" && nextStatus !== 'ARCH' && this.props.job.status !== 'ARCH' && nextStatus === 'RN') {
      this.setState({
        showPaymentError: true,
        paymentError: getErrorMessage("jobs_page_payment_required_error_text"),
      });
    } else {
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Token " + this.props.token,
      };
      var payload = {};
      var route = API_ROOT + "api/v3/employer/jobs/" + this.props.job.id + "/"
      if(this.props.job.status !== "ARCH") {
        payload.status = nextStatus;
      } else {
        route = API_ROOT + "api/v3/employer/jobs/" + this.props.job.id + "/unarchive/"
      }
      if (!nextStatus === "ARCH" && !this.props.job.status === "ARCH") {
        this.setState({ statusUpdateLoading: true });
      }
      await (this.props.job.status === 'ARCH' ? 
        axios.get(route, { headers: headers }) : 
        axios.patch(route, payload, { headers: headers }) 
      )
        .then((response) => {
          if (response && response.status >= 200 && response.status < 300) {
            if (nextStatus === "ARCH" || this.props.job.status === "ARCH") {
              this.props.showArchiveSnackbar();
            }
            this.setState({ showAddJobs: false }, () =>
              this.props.getJobs("update-status")
            );
            setTimeout(
              function () {
                this.setState({ statusUpdateLoading: false });
              }.bind(this),
              3000
            );
          } else {
            this.setState({
              statusUpdateLoading: false,
              showError: true,
              error: getErrorMessage("from_api_response", {
                apiErrorMessage: response.data.error,
              }),
            });
          }
        })
        .catch((err) => {
          this.setState({ statusUpdateLoading: false });
          if (err.response.status === 402) {
            if (this.props.subscriptionData.subscription_status !== "IS") {
              this.setState({
                showPaymentError: true,
                paymentError: getErrorMessage(
                  "jobs_page_payment_required_error_text"
                ),
              });
            } else {
              if (
                (this.props.job.job_type === "PT1" &&
                  this.props.subscriptionData.subscription_data.plan
                    .is_all_in_one) ||
                (this.props.job.job_type !== "PT1" &&
                  !this.props.subscriptionData.subscription_data.plan
                    .is_all_in_one)
              ) {
                this.setState({
                  showPaymentError: true,
                  paymentError: getErrorMessage("mismatched_plan_purchased", {
                    planName:
                      this.props.subscriptionData.subscription_data.plan
                        .name_jp,
                  }),
                });
              } else {
                this.setState({
                  showPaymentError: true,
                  paymentError: getErrorMessage("plan_max_reached", {
                    planName:
                      this.props.subscriptionData.subscription_data.plan
                        .name_jp,
                  }),
                });
              }
            }
          }
        });
    }
  }

  requestUpdateSlot(id) {
    this.setState({ loading: true });
    updateSlots(id)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({ showAddJobs: false, loading: false }, () =>
            this.props.getJobs()
          );
        } else {
          this.setState({
            loading: false,
            showError: true,
            error: getErrorMessage("from_api_response", {
              apiErrorMessage: response.data.error,
            }),
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, showError: true });
      });
  }

  handleErrorClose = () => {
    this.setState({
      showError: false,
      error: "",
      paymentError: "",
      showPaymentError: false,
    });
  };

  handleCloseRedirect() {
    if (this.props.subscriptionData.subscription_status !== "IS") {
      this.props.history.push("/dashboard/billing");
    } else {
      this.handleErrorClose();
    }
  }

  stopJob() {
    this.updateStatus("ST", this.props.job.id);
    this.setState({ showJobStopConfirmation: false });
  }

  handleClose = () => {
    this.setState({
      showSuccess: false,
      showImageUploadSuccess: false,
      successMessage: "",
    });
  };

  updateApplicantStatus(payload, id) {
    updateApplicantStatusAPI(payload, id)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({ showAddJobs: false, loading: false }, () => {
            this.setState({ showSuccess: true });
            this.props.getJobs();
          });
        } else {
          this.setState({
            loading: false,
            showError: true,
            error: response.response.data.error
              ? getErrorMessage("from_api_response", {
                  apiErrorMessage: response.response.data.error,
                })
              : "",
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, showError: true });
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getSeekerJobData = () => {
    this.setState({ loading: true });
    getSeekerJobData(this.props.job.id)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({ seekerData: response.data, previewJobOpen: true, loading: false });
        } else {
          this.setState({
            loading: false,
            showError: true,
            error: getErrorMessage("from_api_response", {
              apiErrorMessage: response.data.error,
            }),
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, showError: true });
      });
  }

  // componentDidMount() {
  //   if(this.props.job.status == 'RN') {
  //     this.getSeekerJobData()
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.subscriptionData !== this.props.subscriptionData) {
      var subscriptionData = this.props.subscriptionData;
      this.setState({ subscriptionData });
    }
  }

  hideOverlay(id) {
    var elementId = id + "-overlay";
    var element = document.getElementById(elementId);
    element.style.display = "none";
  }

  deleteOldImages(id) {
    this.setState({ loading: true });
    this.deleteImage(this.props.job.id, id);
  }

  moveImages(id, direction='LEFT') {
    this.setState({ loading: true });
    this.moveImage(this.props.job.id, id, direction)
  }

  deleteImage(jobId, pic) {
    var payload = {
      image_id: parseInt(pic),
    };
    deleteImage(jobId, payload)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          response.json().then((json) => {
            this.setState({ loading: false }, () =>
              this.props.showImageUploadSuccess(json, "delete")
            );
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  moveImage(jobId, pic, dir) {
    var payload = {
      image_id: parseInt(pic),
      direction: dir
    };
    moveImage(jobId, payload)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState({ loading: false }, () => {
            this.props.moveImageSuccess(jobId, payload.image_id)
          }
          );
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((e) => {
        this.setState({ loading: false });
      });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      bstr = window.atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var blob = new Blob([u8arr], { type: "image/png" });
    blob.lastModifiedDate = new Date();
    return blob;
    // return new Blob([u8arr], filename, {type:"image/png"});
  }

  addPictures() {
    var pictureLabels = ["image1", "image2", "image3", "image4", "image5"];
    var picturesPayload;
    if (this.state.selectedFile.length > 0) {
      picturesPayload = new FormData();
      this.state.selectedFile.map((pic, id) => {
        picturesPayload.append(pictureLabels[id], pic);
      });
    } else if (this.state.selectedFile.id) {
      picturesPayload = {};
      picturesPayload.image_ids = [this.state.selectedFile.id];
    }

    addPicturesAPI(picturesPayload, this.props.job.id)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          this.setState(
            { loading: false },
            this.props.showImageUploadSuccess(response.data, "add")
          );
        } else {
          this.setState({
            loading: false,
            error: response.response.data.error
              ? getErrorMessage("from_api_response", {
                  apiErrorMessage: response.response.data.error,
                })
              : "",
          });
        }
      })
      .catch((e) => {
        this.setState({ loading: false, showError: false });
      });
  }

  compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compress(file, {
        convertSize: 1, // the max size in MB, defaults to 2MB
        quality: 0.75, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: "contain", // defaults to true, set false if you do not want to resize the image width and height
        success: (result) => {
          resolve(new File([result], file.name, { type: result.type }));
        },
        error: (error) => reject(error),
      });
    });
  }

  fileChangeHandler(event) {
    // this.setState({selectedFile: event.target.files})
    var fileArray = [];
    fileArray[0] = event.target.files[0];
    const compressPromises = [];
    for (const file of fileArray) {
      compressPromises.push(this.compressImage(file));
    }
    Promise.all(compressPromises)
      .then((data) => {
        // returns an array of compressed images
        this.setState(
          {
            selectedFile: data,
          },
          () => this.addPictures()
        );
      })
      .catch((e) => {
        // console.log(e);
      });
  }

  redirect(url, job) {
    this.props.history.push({
      pathname: url,
      state: {
        job_type: job.job_type,
      },
    });
  }

  render() {
    const { job } = this.props;
    const {
      statusUpdateLoading,
      showError,
      error,
      showJobStopConfirmation,
      showPaymentError,
      paymentError,
      subscriptionData,
    } = this.state;

    let switched = false;
    let nextStatus = "RN";
    let currentStatus = DashboardTranslations.jobs_page_status_created;
    if (job.status === "RN") {
      currentStatus = DashboardTranslations.jobs_page_status_running;
      switched = true;
      nextStatus = "ST";
    } else if (job.status === "ST") {
      currentStatus = DashboardTranslations.jobs_page_status_stopped;
      nextStatus = "RN";
      switched = false;
    } else if (job.status === "UP") {
      currentStatus = DashboardTranslations.jobs_page_status_updated;
      nextStatus = "RN";
      switched = false;
    } else if (job.status === "TR") {
      currentStatus = DashboardTranslations.jobs_page_status_terminated;
      nextStatus = "";
    }

    let imagesList = [];
    let archiveAllowed = job.status !== "RN" && job.status !== "ARCH";

    for (let i = 0; i < 5; i++) {
      if (job.work_images[i]) {
        const id = job.work_images[i].id;
        imagesList[i] = (
          <div>
            <div className="already-added-image-container">
              {id && job.status !== 'ARCH' && (
                <img
                  alt="close-button"
                  className="close-icon"
                  src={closeIcon}
                  onClick={() => this.deleteOldImages(id)}
                />
              )}
              {i > 0 && id && job.status !== 'ARCH' && (
                <img
                  alt="move-button"
                  className="move-icon"
                  src={moveIcon}
                  onClick={() => this.moveImages(id)}
                />
              )}
              <img
                alt="tooltip"
                className="already-added-image"
                src={job.work_images[i].url}
              />
            </div>
            {i === 0 && (
              <div style={{ color: "#1A9EFF", fontSize: 12 }}>
                記事内メイン画像
              </div>
            )}
          </div>
        );
      } else {
        if (i === 0 && job.default_image) {
          imagesList[0] = (
            <div>
              <div className="already-added-image-container">
                <img
                  alt="tooltip"
                  className="already-added-image"
                  src={job.default_image.url}
                />
              </div>
              <div style={{ color: "#1A9EFF", fontSize: 12 }}>
                記事内メイン画像
              </div>
            </div>
          );
        } else {
          imagesList[i] = (
            <div className="image-upload">
              <label htmlFor={"file-input" + job.id + i}>
                <div className={`single-job-image${job.status === 'ARCH' ? '-disabled' : ''}`} />
              </label>
              <input
                type="file"
                accept="image/*"
                id={"file-input" + job.id + i}
                onChange={(event) => {
                  if(job.status !== 'ARCH') {
                    this.fileChangeHandler(event)
                  }
                }}
                disabled={job.status === 'ARCH'}
                alt={"image-upload" + job.id + i}
              />
            </div>
          );
        }
      }
    }

    const jobTypeUiOption = {
      PT1: {
        color: "#FC6B6D",
        text: "アルバイト",
      },
      PT2: {
        color: "#28C1DD",
        text: "アルバイト",
      },
      FT: {
        color: "#1A9EFF",
        text: "正社員",
      },
      SSV: {
        color: "#7B70F7",
        text: "特定技能",
      },
    };

    return (
      <div className="job-cell-container">
        <div
          className={`job-cell-header ${
            job.status === "ARCH" ? "cell-header-archived" : ""
          }`}
          style={{ alignItems: "center" }}
        >
          <div className="header-left">
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: "6px",
                  padding: "3px 8px",
                  background: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: jobTypeUiOption[job.job_type].color,
                }}
                className={`job-type-button ${
                  job.status === "ARCH" ? "job-type-button-archived" : ""
                }`}
              >
                {jobTypeUiOption[job.job_type].text}
              </div>
              <div className="job-name" style={{ marginLeft: "10px" }}>
                {job.work_location.brand_name_jp} - {job.work_location.branch} -{" "}
                {job.subcategory}
              </div>
            </div>
            <div className="interviewer-name">
              <div>面接官: {job.interview_in_charge.display_name}</div>
              <div className="location-id">
                　面接場所:{" "}
                {job.interview_location
                  ? job.interview_location.web_id
                  : "オンライン面接"}
              </div>
            </div>
            {job.expiry_timestamp && (
              <>
                <div className="blank-line-for-mobile" />
                <div className="interviewer-name">
                  <div style={{ fontSize: 12 }}>
                    面接枠の数: {job.open_slots_count}{" "}
                    （面接枠はサービス利用期間終了から10日先の
                    {this.convertExpiryTimestamp(job.expiry_timestamp)}{" "}
                    までの間で登録してください）
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="header-right">
            <ScaleLoader
              sizeUnit={"px"}
              height={35}
              width={3}
              color={"#FFFFFF"}
              loading={statusUpdateLoading}
            />
            {!statusUpdateLoading && job.status !== "ARCH" && (
              <div className="switch-inner-container">
                <div className="switch-inner">
                  <Switch
                    checked={switched}
                    onChange={() =>
                      nextStatus === "ST"
                        ? this.setState({ showJobStopConfirmation: true })
                        : this.updateStatus(nextStatus, job.id)
                    }
                    onColor="#FFFFFF"
                    onHandleColor="#33A8ED"
                    offColor="#FFFFFF"
                    offHandleColor="#FE7374"
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "#FE7374",
                          paddingRight: 2,
                        }}
                      >
                        Off
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "#33A8ED",
                          paddingRight: 2,
                        }}
                      >
                        On
                      </div>
                    }
                    className="react-switch"
                    id="icon-switch"
                  />
                </div>
                <div className="text">{currentStatus}</div>
                <div>
                  {job.interview_location === null && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        src={videoIcon}
                        alt="Video Icon"
                        style={{ height: "15px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`job-details-container ${
            job.status === "ARCH" ? "job-details-container-archived" : ""
          }`}
        >
          <div
            className={`job-images-container ${
              job.status === "ARCH" ? "job-images-container-archived" : ""
            }`}
          >
            {imagesList}
          </div>
          <div className="job-action-container">
            <Tooltip
              title={
                job.status === "ARCH"
                  ? "この求人記事はアーカイブされているため\n面接枠の追加はできません。"
                  : ""
              }
              placement="bottom-end"
            >
              <button
                className={`btn btn-dimension job-button ${
                  job.status !== "ARCH" ? "btn-primary" : "btn-disabled"
                }`}
                onClick={() => {
                  if (job.status !== "ARCH") {
                    this.props.history.push(
                      "/dashboard/add-interview-times/" + this.props.job.id
                    );
                  }
                }}
              >
                {DashboardTranslations.jobs_page_interview_times_button_text}
              </button>
            </Tooltip>
            {(job.status === "RN" || job.status === 'ARCH') && (
              <Tooltip
                title={job.status === "RN" ? "掲載をOffにすると、記事の編集ができます" : ''}
                placcement="bottom"
              >
                <button
                  className="btn btn-primary btn-dimension job-button"
                  onClick={() =>
                    this.getSeekerJobData()
                  }
                >
                  {DashboardTranslations.jobs_page_view_job_button_text}
                </button>
              </Tooltip>
            )}
            {!((job.status === "RN" || job.status === 'ARCH')) && (
              <button
                className="btn btn-primary btn-dimension job-button"
                onClick={() =>
                  this.redirect("/dashboard/edit-job/" + job.id, job)
                }
              >
                {DashboardTranslations.jobs_page_edit_job_button_text}
              </button>
            )}
            <button
              className="btn btn-primary btn-dimension job-button"
              onClick={() =>
                this.redirect("/dashboard/copy-job/" + job.id, job)
              }
            >
              {DashboardTranslations.jobs_page_copy_job_button_text}
            </button>
            {job.status === "ARCH" ? (
              <button
                className="btn btn-primary btn-dimension job-button"
                onClick={() => {
                  this.updateStatus("ST", job.id);
                }}
              >
                アーカイブを元に戻す
              </button>
            ) : (
              <Tooltip
                title={
                  !archiveAllowed
                    ? "この求人記事は掲載中のため\nアーカイブできません"
                    : ""
                }
                placement="bottom-end"
              >
                <button
                  className={`btn btn-dimension job-button archive-svg ${
                    archiveAllowed ? "btn-primary" : "btn-disabled"
                  }`}
                  onClick={() => {
                    if (archiveAllowed) {
                      this.updateStatus("ARCH", job.id);
                    }
                  }}
                >
                  {archiveAllowed ? (
                    <svg
                      width="32"
                      height="33"
                      viewBox="0 0 32 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 8.72656C2 7.62199 2.89543 6.72656 4 6.72656H28C29.1046 6.72656 30 7.62199 30 8.72656V11.7266C30 12.8311 29.1046 13.7266 28 13.7266H4C2.89543 13.7266 2 12.8311 2 11.7266V8.72656ZM28 8.72656H4V11.7266H28V8.72656Z"
                        fill="#33A8ED"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5 11.7266C5.55228 11.7266 6 12.1743 6 12.7266V24.7266H26V12.7266C26 12.1743 26.4477 11.7266 27 11.7266C27.5523 11.7266 28 12.1743 28 12.7266V24.7266C28 25.257 27.7893 25.7657 27.4142 26.1408C27.0391 26.5159 26.5304 26.7266 26 26.7266H6C5.46957 26.7266 4.96086 26.5159 4.58579 26.1408C4.21071 25.7657 4 25.257 4 24.7266V12.7266C4 12.1743 4.44772 11.7266 5 11.7266Z"
                        fill="#33A8ED"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 17.7266C12 17.1743 12.4477 16.7266 13 16.7266H19C19.5523 16.7266 20 17.1743 20 17.7266C20 18.2788 19.5523 18.7266 19 18.7266H13C12.4477 18.7266 12 18.2788 12 17.7266Z"
                        fill="#33A8ED"
                      />
                    </svg>
                  ) : (
                    <img
                      src={archiveIcon}
                      alt="Archive Icon"
                      style={{ height: 32 }}
                    />
                  )}
                  アーカイブする
                </button>
              </Tooltip>
            )}
          </div>
        </div>

        <ErrorModal
          error={error}
          handleClose={this.handleErrorClose.bind(this)}
          showError={showError}
        />
        <ErrorModal
          error={paymentError}
          handleClose={this.handleErrorClose.bind(this)}
          showError={showPaymentError}
          handleCloseRedirect={this.handleCloseRedirect.bind(this)}
        />
        <ConfirmModal
          confirmAction={this.stopJob.bind(this)}
          confirmationText={
            DashboardTranslations.jobs_page_stop_job_confirmation_message
          }
          handleClose={this.closeConfirmation.bind(this)}
          showConfirmationModal={showJobStopConfirmation}
        />
        <SuccessModal
          successMessage={
            DashboardTranslations.jobs_page_feedback_updated_successfully
          }
          handleClose={this.handleClose.bind(this)}
          showSuccess={this.state.showSuccess}
        />
        {this.state.previewJobOpen && <div class='preview-popup'>
            <div className='preview-popup-box'>
              <svg onClick={() => this.setState({previewJobOpen: false})} class="preview-popup-close" xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.588 0.58294C20.9785 0.996262 20.9785 1.66639 20.588 2.07971L2.58797 21.1305C2.19744 21.5438 1.56428 21.5438 1.17375 21.1305C0.783228 20.7171 0.783228 20.047 1.17375 19.6337L19.1738 0.58294C19.5643 0.169619 20.1974 0.169619 20.588 0.58294Z" fill="black"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.17375 0.58294C1.56428 0.169619 2.19744 0.169619 2.58797 0.58294L20.588 19.6337C20.9785 20.047 20.9785 20.7171 20.588 21.1305C20.1974 21.5438 19.5643 21.5438 19.1738 21.1305L1.17375 2.07971C0.783228 1.66639 0.783228 0.996262 1.17375 0.58294Z" fill="black"/>
              </svg>
              <div class="preview-popup-title">
                記事のプレビュー
              </div>
              <div class="preview-popup-sub">
                求職者に見える形式で表示しています。
              </div>
              <SeekerIFrame jobData={{...this.state.seekerData, is_view: true}}/>
            </div>
          </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    language: state.language,
    token: state.user.token,
  };
};

JobCell = connect(mapStateToProps)(JobCell);

export default JobCell;
